import './Button.css'

const Button = ({view = 'clear', iconOnly = false, icon, text, disabled = false, link, onClick}) => {
    let styles = ''
    if(iconOnly == true) styles += ' button_iconOnly'
    if(disabled == true) styles += ' button_disabled'

    return (
        <>
        {
            link ?
            (<a href={link} className={`button button_view-${view} ${styles}`} onClick={onClick}>
                {icon}
                {text}
            </a>)
            :
            (<button className={`button button_view-${view} ${styles}`} onClick={onClick}>
                {icon}
                {text}
            </button>)
        }
        </>
    )
}

export default Button