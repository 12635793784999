import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'

import Layout from '../components/Layout/Layout'
import Button from '../components/Button/Button'
import IconCheck from '../components/Icon/IconCheck'
import IconPublish from '../components/Icon/IconPublish'
import IconUnpublish from '../components/Icon/IconUnpublish'

import getTopNumbers from '../utils/getTopNumbers'
import getLeftNumbers from '../utils/getLeftNumbers'
import drawLine from '../utils/drawLine'
import undrawLine from '../utils/undrawLine'

const AdminColorPickle = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get('id')
    
    const [isPublished, setIsPublished] = useState(null)
    const [pickle, setPickle] = useState(null)
    const [matrix, setMatrix] = useState(null)
    const [topNumbers, setTopNumbers] = useState(null)
    const [leftNumbers, setLeftNumbers] = useState(null)
    const [color, setColor] = useState(1)
    const [difficultyInput, setDifficultyInput] = useState('')
    const [nameInput, setNameInput] = useState('')

    let cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')
    let selectedCells = null
    let colorProperties = {}


    function updateMatrix(obj) {
        let newMatrix = matrix
        if(obj['type'] == 'pickleMatrix__cell-checked') {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = parseInt(obj['color'])
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = parseInt(obj['color'])
        } else if(obj['type'] == 'pickleMatrix__cell-skipped') {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = 0
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = 0
        } else if(obj['type'] == '') {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = ''
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = ''
        }
        setMatrix(newMatrix)
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER}/get-pickle`, {
            params: {id: parseInt(id)}
        }).then((response) => {
            const solution = response.data['solution']
            const columns = solution[0].length
            const rows = solution.length
            const top = getTopNumbers({solution: solution, numberOfColumns: columns, numberOfRows: rows})
            const left = getLeftNumbers({solution: solution, numberOfColumns: columns, numberOfRows: rows})

            const data = {
                'id': response.data['id'],
                'name': response.data['name'],
                'likes': response.data['likes'],
                'creation_date': response.data['creation_date'],
                'columns': columns,
                'rows': rows,
                'solution': response.data['solution'],
                'color': response.data['color'],
                'difficulty': response.data['difficulty'],
                'colors': response.data['colors'],
                'published': response.data['published']
            }
            setTopNumbers(top['markup'])
            setLeftNumbers(left['markup'])
            setPickle(data)
            setMatrix(response.data['solution'])
            setDifficultyInput(response.data['difficulty'])
            setNameInput(response.data['name'])
            setIsPublished(response.data['published'])
        })
    }, [])

    function OnCellMouseEnter(e) {
        const x = parseInt(e.target.getAttribute('axisx'))
        const y = parseInt(e.target.getAttribute('axisy'))
        
        if(selectedCells != null) drawLine(x, y, selectedCells, matrix, pickle)
    }
    function OnCellMouseLeave(e) {
        const x = e.target.getAttribute('axisx')
        const y = e.target.getAttribute('axisy')
    }
    function OnCellMouseDown(e) {
        cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')
        e.preventDefault()

        const x = parseInt(e.target.getAttribute('axisx'))
        const y = parseInt(e.target.getAttribute('axisy'))
        selectedCells = {}
        
        selectedCells['firstCell'] = [x, y]
        if(e.shiftKey || (e.button == 2)) {
            selectedCells['type'] = 'pickleMatrix__cell-skipped'
        } else if(!e.shiftKey && (e.button != 2) && (e.target.classList.contains(`pickleMatrix__cell-checked`))) {
            selectedCells['color'] = color
            selectedCells['type'] = ''
        } else if(!e.shiftKey && (e.button != 2) && (e.target.classList.contains('pickleMatrix__cell-skipped'))) {
            selectedCells['type'] = ''
        } else {
            selectedCells['type'] = 'pickleMatrix__cell-checked'
            selectedCells['color'] = color
        }
    }
    function OnCellMouseUp(e) {
        const x = parseInt(e.target.getAttribute('axisx'))
        const y = parseInt(e.target.getAttribute('axisy'))
        const top = getTopNumbers({solution: matrix, numberOfColumns: pickle['columns'], numberOfRows: pickle['rows']})
        const left = getLeftNumbers({solution: matrix, numberOfColumns: pickle['columns'], numberOfRows: pickle['rows']})
        
        drawLine(x, y, selectedCells, matrix, pickle)
        updateMatrix(selectedCells)
        cursorNumber.classList.add('pickleMatrix__cursorNumber-hidden')
        cursorNumber.innerHTML = ''

        selectedCells = null
        setTopNumbers(top['markup'])
        setLeftNumbers(left['markup'])
        console.log(matrix)
    }
    function OnPuzzleMouseLeave(e) {
        const x = e.target.getAttribute('axisx')
        const y = e.target.getAttribute('axisy')

        undrawLine(selectedCells)
    }
    function OnNumberClick(e) {
        const cellColor = e.target.getAttribute('color')
        if(cellColor) setColor(cellColor)
    }
    function onPaletteClick(e) {
        const className = 'artboard__example-checked'
        const previous = document.querySelector(`.${className}`)
        
        if(previous) previous.classList.remove(className)
        e.target.classList.add(className)
        setColor(e.target.getAttribute('color'))
    }

    const handleUpdate = async ({solution, published, difficulty, name}) => {
        let query = {id: pickle['id']}
        if(solution) query.solution = solution
        if(published == true || published == false) query.published = published
        if(difficulty) query.difficulty = difficulty
        if(name) query.name = name
        
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/update-pickle`, {
                params: query
            })
            if(response.data) {
                console.log(query)
                return true
            }
            else return false
        } catch (error) {
            console.log(error)
        }
    }
    function OnUpdateSolution(e) {
        handleUpdate({solution: matrix})
    }
    function OnPublish(e) {
        handleUpdate({published: true})
        setIsPublished(true)
    }
    function OnUnpublish(e) {
        handleUpdate({published: false})
        setIsPublished(false)
    }
    function OnUpdateName(e) {
        setNameInput(e.target.value)
        handleUpdate({name: e.target.value})
    }
    function OnUpdateDifficulty(e) {
        setDifficultyInput(e.target.value)
        handleUpdate({difficulty: e.target.value})
    }

    return (
        <>
            {pickle != null && matrix != null && topNumbers != null && leftNumbers != null && (
                <Layout width={'full'} contentClassName={'artboard'} page={'color-pickle'} 
                        style={{ "--cell-color-1": pickle['colors'][0],
                                    "--cell-color-2": pickle['colors'][1],
                                    "--cell-color-3": pickle['colors'][2],
                                    "--cell-color-4": pickle['colors'][3],
                                    "--cell-color-5": pickle['colors'][4],
                                    "--cell-color-6": pickle['colors'][5],
                                    "--cell-color-7": pickle['colors'][6],
                                    "--cell-color-8": pickle['colors'][7],
                                    "--cell-color-9": pickle['colors'][8] }}>
                    <div className='artboard__header'>
                        <div className='text text_size-m text_view-primary text_weight-bold'>#{ pickle['id'] }</div>
                        <input type={'text'} className={'input'} value={nameInput} onChange={OnUpdateName} />
                        <input type={'number'} className={'input'} value={difficultyInput} onChange={OnUpdateDifficulty} />

                        <ul className='artboard__help'>
                            <li className='artboard__helpItem'>
                                {
                                    pickle['colors'].map((item, i) => {
                                        return <div onClick={onPaletteClick} className={`artboard__example artboard__example-palette ${color == (i + 1) ? 'artboard__example-checked' : ''} pickleMatrix__cell pickleMatrix__cell-checked`} color={i+1} key={i}></div>
                                    })
                                }
                                <span>— click</span>
                            </li>
                            <li className='artboard__helpItem'>
                                <div className='artboard__example pickleMatrix__cell pickleMatrix__cell-skipped'></div>
                                <span>— right click or Shift + click</span>
                            </li>
                        </ul>

                        <div className='artboard__btns'>
                            <Button view={'clear'} iconOnly={true} icon={<IconCheck />} onClick={OnUpdateSolution} />
                            { isPublished && <Button view={'clear'} iconOnly={true} icon={<IconPublish />} onClick={OnUnpublish} /> }
                            { !isPublished && <Button view={'clear'} iconOnly={true} icon={<IconUnpublish />} onClick={OnPublish} /> }
                        </div>
                    </div>

                    <div className='artboard__content'>
                        <div className='pickleMatrix pickleMatrix-color' style={{ "--puzzle-cells": pickle['columns'], 
                                                                                    "--left-cells": leftNumbers[0].length,
                                                                                    "--puzzle-top-height": topNumbers.length,
                                                                                    "--puzzle-height": matrix.length, colorProperties }}>
                            <div className='pickleMatrix__cursorNumber pickleMatrix__cursorNumber-hidden'></div>
                            <div className='pickleMatrix__preview'></div>
                            <div className='pickleMatrix__topNumbers'>
                            {
                                topNumbers.map((row, y) => (
                                    <div className='pickleMatrix__row' key={y}>
                                        {
                                            row.map((cell, x) => (
                                                <div onClick={OnNumberClick} onMouseLeave={OnCellMouseLeave} axisx={x} axisy={y} color={cell[1]} className={`pickleMatrix__cell pickleMatrix__number${cell[0] == 0 ? ' pickleMatrix__number-empty' : ''}`} number={cell[0] != 0 ? cell[0] : ''} key={x}></div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                            </div>
                            <div className='pickleMatrix__topNoNumbers'></div>

                            <div className='pickleMatrix__leftNumbers'>
                            {
                                leftNumbers.map((row, y) => (
                                    <div className='pickleMatrix__row' key={y}>
                                        {
                                            row.map((cell, x) => (
                                                <div onClick={OnNumberClick} onMouseLeave={OnCellMouseLeave} axisx={x} axisy={y} color={cell[1]} className={`pickleMatrix__cell pickleMatrix__number${cell[0] == 0 ? ' pickleMatrix__number-empty' : ''}`} number={cell[0] != 0 ? cell[0] : ''} key={x}></div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                            </div>
                            <div className='pickleMatrix__puzzle theme-light' onMouseLeave={OnPuzzleMouseLeave}>
                            {
                                matrix.map((row, y) => (
                                    <div className="pickleMatrix__row" key={y}>
                                        {
                                            row.map((cell, x) => (
                                                <div onContextMenu={e => e.preventDefault()} onMouseDown={OnCellMouseDown} onMouseUp={OnCellMouseUp} onMouseEnter={OnCellMouseEnter} onMouseLeave={OnCellMouseLeave} axisx={x} axisy={y} color={cell} className={`pickleMatrix__cell`} key={x}></div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                            </div>
                            <div className='pickleMatrix__rightNumbers'>
                            {
                                matrix.map((cell, y) => (
                                    (<div className='pickleMatrix__cell' axisy={y} key={y}>{y+1}</div>)
                                ))
                            }
                            </div>

                            <div className='pickleMatrix__bottomNoNumbers'></div>
                            <div className='pickleMatrix__bottomNumbers'>
                            {
                                matrix[0].map((cell, x) => (
                                    (<div className="pickleMatrix__cell" axisx={x} key={x}>{x+1}</div>)
                                ))
                            }
                            </div>
                        </div>
                    </div>
                </Layout>
            )}
            {pickle == null && (
                <Layout width={'full'} contentClassName={'artboard'} page={'color-pickle'}>
                    <div className='loader'></div>
                </Layout>
            )}
        </>
    )
}

export default AdminColorPickle