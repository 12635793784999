import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import axios from 'axios'

import './Login.css'

import Layout from '../../components/Layout/Layout'

const Login = () => {
    const [user_id, setUserId] = useState(localStorage.getItem('user_id'))

    const handleLogin = async (user_name, user_email, user_photo) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/login-signin`, {
                params: {user_name, user_email, user_photo}
            })
            if(response.data) {
                sessionStorage.setItem('user_id', response.data['user_id'])
                localStorage.setItem('user_id', response.data['user_id'])
                localStorage.setItem('user_name', user_name)
                localStorage.setItem('user_photo', user_photo)
                localStorage.setItem('user_email', user_email)
                setUserId(response.data['user_id'])
                return true
            }
            else return false
        } catch (error) {
            console.log(error)
        }
    }

    const loadScript = (FILE_URL, async = true, type = "text/javascript") => {
        return new Promise((resolve, reject) => {
            try {
                const scriptEle = document.createElement("script")
                scriptEle.type = type
                scriptEle.async = async
                scriptEle.src =FILE_URL
    
                scriptEle.addEventListener("load", (ev) => {
                    resolve({ status: true })
                })
    
                scriptEle.addEventListener("error", (ev) => {
                    reject({
                        status: false,
                        message: `Failed to load the script ＄{FILE_URL}`
                    })
                })
    
                document.body.appendChild(scriptEle)
            } catch (error) {
                reject(error)
            }
        })
    }

    useEffect(() => {
        loadScript("https://accounts.google.com/gsi/client")
            .then( data  => {
                /* global google */
                google.accounts.id.initialize({
                    client_id: "496961286928-kuh4baoopp5akua3e4998puktd17h3lk.apps.googleusercontent.com",
                    callback: handleCallbackResponse
                })
        
                google.accounts.id.renderButton(
                    document.getElementById("googleSignIn"),
                    { type: "standard", shape: "pill", theme: "outline", size: "large", text: "continue_with" }
                )
            })
            .catch( err => {
                console.error(err)
            })
    }, [])
    
    function handleCallbackResponse(response) {
        let userObject = jwt_decode(response.credential)
        handleLogin(userObject['name'], userObject['email'], userObject['picture'])
    }

    return (
        <Layout width={'m'} page={'login'} contentClassName={'loginPage'}>
            { user_id && <Navigate replace to="/bw" /> }

            <div className='loginPage__logo'>pickles</div>
            <h1 className="loginPage__h1 text text_size-4xl text_view-primary text_lineHeight-xs text_weight-bold">do you like nonograms, pic-a-pix or griddlers?<br /><span className="text text_weight-bold text_view-brand">try pickles!</span></h1>
            <p className="loginPage__p text text_size-l text_view-primary text_lineHeight-m">it’s a logic puzzle that uses numbers to reveal a hidden image.<br />we have simple tutorials for you to understand pickles puzzles better</p>
            <div className='loginPage__controls'>
                <div className='loginButton' id="googleSignIn"></div>
                <p className='loginPage__note text text_size-s text_view-secondary'>by using pickles, you agree to our<br /><a className='text text_view-link' href="/privacy-and-terms">privacy policy and terms of service</a></p>
            </div>
        </Layout>
    )
}

export default Login