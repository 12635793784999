import { useState, useEffect } from 'react'
import axios from 'axios'

import Layout from '../components/Layout/Layout'
import PicklePreview from '../components/PicklePreview/PicklePreview'

const Color = () => {
    const [pickles, setPickles] = useState(null)
    let user_id = localStorage.getItem('user_id')
    let page = 0
    
    const getPickles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-pickles`, {
                params: {skip: page, color: true, published: true, user_id: user_id}
            })
            if(response.data) {
                if(page >= 1)
                    setPickles(pickles => [...pickles, ...response.data])
                else setPickles(response.data)
                return true
            } else return false
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        let mainSection = document.querySelector('.page__content')
        
        mainSection.addEventListener('scroll', e => {
            if((window.innerHeight + e.target.scrollTop + 1) >= e.target.scrollHeight) {
                page++
                getPickles()
            }
        })
        
        getPickles()
    }, [])
    
    return (
        <Layout page={'color'} width={'m'}>
            {pickles == null && (
                <div className='loader'></div>
            )}
            {pickles != null && pickles.length > 0 && (
                <>
                    <h1 className="content__header text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold">color pickles</h1>
                    <ul className="pickleList">
                        { pickles.map((item, key) => {
                            return ( 
                                <PicklePreview pickle={item} key={key} />)
                        }) }
                    </ul>
                </>
            )}
            {pickles && pickles.length == 0 && (
                <>
                    <h1 className="text text_size-2xl text_view-primary text_lineHeight-xs text_weight-bold text_align-center">there is no pickles</h1>
                    <p className="text text_size-m text_view-primary text_lineHeight-s text_align-center">maybe you've already solved them all?</p>
                </>
            )}
        </Layout>
    )
}

export default Color