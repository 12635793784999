import { useState, useEffect } from 'react'

import AdminAll from './AdminAll'
import AdminDraft from './AdminDraft'
import AdminPublished from './AdminPublished'

import Layout from '../components/Layout/Layout'
import Button from '../components/Button/Button'

const AdminBW = () => {    
    const [activeTab, setActiveTab] = useState('all')

    function onAllClick() {
        setActiveTab('all')
    }
    function onDraftClick() {
        setActiveTab('draft')
    }
    function onPublishedClick() {
        setActiveTab('published')
    }
    
    return (
        <Layout width={'m'} page='adminbw'>
            <h1 className="content__header text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold">black & white pickles</h1>
            <div style={{display: 'flex', gap: '20px', marginBottom: '40px'}}>
                <Button view={activeTab == 'all' ? 'primary' : 'clear'} text={'all'} onClick={onAllClick} />
                <Button view={activeTab == 'published' ? 'primary' : 'clear'} text={'published'} onClick={onPublishedClick} />
                <Button view={activeTab == 'draft' ? 'primary' : 'clear'} text={'draft'} onClick={onDraftClick} />
            </div>

            { activeTab == 'all' && <AdminAll isColor={false} /> }
            { activeTab == 'draft' && <AdminDraft isColor={false} /> }
            { activeTab == 'published' && <AdminPublished isColor={false} /> }
        </Layout>
    )
}

export default AdminBW