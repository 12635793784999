import { useState, useEffect } from 'react'
import axios from 'axios'

import PicklePreview from '../components/PicklePreview/PicklePreview'

const AdminBWPublished = ({  isColor }) => {
    let page = 0
    const [pickles, setPickles] = useState([])
    
    const getPickles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-pickles`, {
                params: {skip: page, color: isColor, published: true}
            })
            if(response.data) {
                let data = response.data

                if(page == 0) setPickles(data)
                else setPickles(oldPickles => [...oldPickles, ...data])

                return true
            } else return false
        } catch (error) {
            console.log(error)
        }
    }

    const handleScroll = (e) => {
        if((window.innerHeight + e.target.scrollTop + 1) >= e.target.scrollHeight) {
            page++
            getPickles()
        }
    }

    useEffect(() => {
        let mainSection = document.querySelector('.page__content')
        
        mainSection.addEventListener('scroll', handleScroll)
        getPickles()
    }, [])
    
    return (
        <>
        {pickles.length > 0 &&
            <ul className="pickleList">
                {
                    pickles.map((item, key) => {
                        return (<PicklePreview pickle={item} isLiked={false} solved={true} admin={true} />)
                    })
                }
            </ul>
        }

        {pickles == [] && (
            <div className='loader'></div>
        )}
        </>
    )
}

export default AdminBWPublished