import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import getAuthor from '../utils/getAuthor'

const Home = () => {
    const [user, setUser] = useState(null)
    let user_id = localStorage.getItem('user_id')
    
    if(sessionStorage.getItem('user_id') != user_id) {
        localStorage.clear()
        sessionStorage.clear()
        user_id = null
    }

    useEffect(() => {
        if(user_id != null) getAuthor({id: user_id, setAuthor: setUser})
    }, [])

    return (
        <>
            { user_id === null && <Navigate replace to="/login" /> }
            { user_id !== null && user != null && user['pickles_solved'].length > 0 && <Navigate replace to="/bw" /> }
            { user_id !== null && user != null && user['pickles_solved'].length == 0 && <Navigate replace to="/tutorial" /> }
        </>
    )
}

export default Home