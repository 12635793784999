const showCursorNumber = ({length, height, x, y, selectedCells, matrix, pickle}) => {
    // if it's a line of a color cells
    if(selectedCells != null && selectedCells['array'] && selectedCells['color'] > 0) {
        let cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')
        let content = ''

        // if it's a vertical line
        if(length == 1 && selectedCells != null) {
            let help = height
            let isLine = true
            let helpY = selectedCells['array'][0][1] - 1
            
            // check if there's another similar cells to the top
            while (isLine && helpY >= 0) {
                if(matrix[helpY][x] == selectedCells['color']) help++
                else isLine = false
                helpY--
            }
            
            helpY = selectedCells['array']
            helpY = helpY[helpY.length - 1]
            helpY = helpY[1]
            helpY++
            isLine = true
            
            // check if there's another similar cells to the bottom
            while (isLine && helpY < pickle['rows']) {
                if(matrix[helpY][x] == selectedCells['color']) help++
                else isLine = false
                helpY++
            }
            content = help

        // if it's a horizontal line
        } else if(height == 1 && selectedCells != null) {
            let help = length
            let isLine = true
            let helpX = selectedCells['array'][0][0] - 1

            // check if there's another similar cells to the left
            while (isLine && helpX >= 0) {
                if(matrix[y][helpX] == selectedCells['color']) help++
                else isLine = false
                helpX--
            }
            
            helpX = selectedCells['array']
            helpX = helpX[helpX.length - 1]
            helpX = helpX[0]
            helpX++
            isLine = true

            // check if there's another similar cells to the right
            while (isLine && helpX < pickle['columns']) {
                if(matrix[y][helpX] == selectedCells['color']) help++
                else isLine = false
                helpX++
            }
            content = help

        // if it's a matrix
        } else content = `${length}×${height}`
        
        // place the popup under the cursor
        // and place the number inside
        if(cursorNumber) {
            cursorNumber.setAttribute('number', content)
        }
    }
}

export default showCursorNumber