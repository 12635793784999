import axios from 'axios'
import { useState, useEffect } from 'react'

import './colorPickleMatrix.css'

import MatrixHeader from '../../components/MatrixHeader/MatrixHeader'
import Matrix from '../../components/Matrix/Matrix'

import getNumberOfCheckedCells from '../../utils/getNumberOfCheckedCells'

const ColorMatrix = ({pickle, setWin, setMatrix, matrix, id, user_id}) => {
    let cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')
    
    const [color, setColor] = useState(1)
    const [history, setHistory] = useState([[...Array(pickle['rows'])].map(x => [...Array(pickle['columns'])].map(y => ''))])
    const [historyStep, setHistoryStep] = useState(0)
    const [progress, setProgress] = useState(0)
    const [zoom, setZoom] = useState(20)

    function updateMatrix(obj) {
        let newMatrix = matrix
        
        if(obj && !obj['isEmpty'] && !obj['isSkipped']) {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = parseInt(obj['color'])
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = parseInt(obj['color'])
        } else if(obj && obj['isSkipped']) {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = 0
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = 0
        } else if(obj && obj['isEmpty']) {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = ''
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = ''
        }
        
        let checkedNumbers = getNumberOfCheckedCells({solution: newMatrix, numberOfColumns: pickle['columns'], numberOfRows: pickle['rows']})
        let progressPercent = Math.floor(checkedNumbers/(pickle['nemberOfCheckedCells'] / 100))
        if(checkedNumbers == pickle['nemberOfCheckedCells']) progressPercent = 100
        
        setMatrix(newMatrix)
        setProgress(progressPercent)
    }

    function userWin() {
        setWin(true)
        axios.put(`${process.env.REACT_APP_SERVER}/add-win`, {
            params: {pickle_id: id, user_id: user_id, difficulty: pickle['difficulty'], isColor: true}
        })
    }

    useEffect(() => {
        const headerHeight = document.querySelector('.artboard__header').offsetHeight
        const navWidth = document.querySelector('.header').offsetWidth
        cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')

        window.addEventListener('keydown', (e) => {
            if(e.defaultPrevented) { return }
            
            switch (e.key) {
                case "1":
                    if(pickle['colors'].length >= 1) setColor(1)
                    break
                case "2":
                    if(pickle['colors'].length >= 2) setColor(2)
                    break
                case "3":
                    if(pickle['colors'].length >= 3) setColor(3)
                    break
                case "4":
                    if(pickle['colors'].length >= 4) setColor(4)
                    break
                case "5":
                    if(pickle['colors'].length >= 5) setColor(5)
                    break
                case "6":
                    if(pickle['colors'].length >= 6) setColor(6)
                    break
                case "7":
                    if(pickle['colors'].length >= 7) setColor(7)
                    break
                case "8":
                    if(pickle['colors'].length >= 8) setColor(8)
                    break
                case "9":
                    if(pickle['colors'].length == 9) setColor(9)
                    break
                default:
                    return
            }
            e.preventDefault();
        }, true)
        window.addEventListener('mousemove', (e) => {
            const top = e.clientY - headerHeight + 15
            const left = e.clientX - navWidth + 15
            if(e.defaultPrevented) { return }
            
            cursorNumber.setAttribute('style', `transform: translate(${left}px, ${top}px)`)
            e.preventDefault();
        }, true)
    }, [])

    return (
        <main className="artboard" style={{ "--cell-color-1": pickle['colors'][0],
                                            "--cell-color-2": pickle['colors'][1],
                                            "--cell-color-3": pickle['colors'][2],
                                            "--cell-color-4": pickle['colors'][3],
                                            "--cell-color-5": pickle['colors'][4],
                                            "--cell-color-6": pickle['colors'][5],
                                            "--cell-color-7": pickle['colors'][6],
                                            "--cell-color-8": pickle['colors'][7],
                                            "--cell-color-9": pickle['colors'][8]}}>

            <MatrixHeader pickle={pickle} color={color} setColor={setColor} zoom={zoom} setZoom={setZoom} setMatrix={setMatrix} setProgress={setProgress} updateMatrix={updateMatrix} historyStep={historyStep} setHistoryStep={setHistoryStep} history={history} setHistory={setHistory} />
            <Matrix pickle={pickle} zoom={zoom} color={color} matrix={matrix} setMatrix={setMatrix} progress={progress} setProgress={setProgress} history={history} updateMatrix={updateMatrix} setHistory={setHistory} setHistoryStep={setHistoryStep} historyStep={historyStep} userWin={userWin} setColor={setColor} isColor={true} />
        </main>
    )
}

export default ColorMatrix