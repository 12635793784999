import { useState, useEffect } from 'react'

import User from '../User/User'
import IconBW from '../Icon/IconBW'
import IconBWAdmin from '../Icon/IconBWAdmin'
import IconColor from '../Icon/IconColor'
import IconColorAdmin from '../Icon/IconColorAdmin'
import IconTutorial from '../Icon/IconTutorial'
import './Header.css'

const Header = ({page}) => {
    const user_id = localStorage.getItem('user_id')
    const user_name = localStorage.getItem('user_name')
    const user_photo = localStorage.getItem('user_photo')

    return (
        <header className="header">
            <div className="header__logo"></div>
            <nav className='header__navwrap'>
                <ul className="header__nav">
                    <li>
                        <a href="/bw" className={`header__link${page == 'bw' ? ' header__link-active' : ''}`}>
                            <div className='header__icon'><IconBW /></div>
                            <div className={`text text_size-s ${page == 'bw' ? 'text_view-primary' : 'text_view-secondary'} text_align-center`}>b&w</div>
                        </a>
                    </li>
                    { user_id == 'b929ae8d-1dae-428c-b5cf-4125db6fad5b' &&
                        <li>
                            <a href="/adminbw" className={`header__link${page == 'adminbw' ? ' header__link-active' : ''}`}>
                                <div className='header__icon'><IconBWAdmin /></div>
                                <div className={`text text_size-s ${page == 'adminbw' ? 'text_view-primary' : 'text_view-secondary'} text_align-center`}>b&w</div>
                            </a>
                        </li>
                    }
                    <li>
                        <a href="/color" className={`header__link${page == 'color' ? ' header__link-active' : ''}`}>
                            <div className='header__icon'><IconColor /></div>
                            <div className={`text text_size-s ${page == 'color' ? 'text_view-primary' : 'text_view-secondary'} text_align-center`}>color</div>
                        </a>
                    </li>
                    { user_id == 'b929ae8d-1dae-428c-b5cf-4125db6fad5b' &&
                        <li>
                            <a href="/admincolor" className={`header__link${page == 'admincolor' ? ' header__link-active' : ''}`}>
                                <div className='header__icon'><IconColorAdmin /></div>
                                <div className={`text text_size-s ${page == 'admincolor' ? 'text_view-primary' : 'text_view-secondary'} text_align-center`}>color</div>
                            </a>
                        </li>
                    }
                    <li>
                        <a href="/tutorial" className={`header__link${page == 'tutorial' ? ' header__link-active' : ''}`}>
                            <div className='header__icon'><IconTutorial /></div>
                            <div className={`text text_size-s ${page == 'tutorial' ? 'text_view-primary' : 'text_view-secondary'} text_align-center`}>tutorial</div>
                        </a>
                    </li>
                </ul>
            </nav>
            { user_id &&
                <User
                    size="m"
                    showName={false}
                    userID={user_id}
                    userName={user_name}
                    userPhoto={user_photo} /> }
        </header>
    )
}

export default Header