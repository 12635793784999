import Layout from '../../components/Layout/Layout'
import './NotFound.css'

const NotFound = () => {
    return (
        <Layout width={'m'} page={'notfound'} contentClassName={'notfound'}>
            <div className='notfound__image'></div>
            <h1 className="text text_size-2xl text_view-primary text_lineHeight-xs text_weight-bold text_align-center">page not found</h1>
            <p className="text text_size-m text_view-primary text_lineHeight-s text_align-center">hmm, the page you were looking for<br />doesn’t seem to exist anymore</p>
            <a href='/bw' className="text text_size-m text_view-link text_lineHeight-s text_align-center">go to pickles</a>
        </Layout>
    )
}

export default NotFound