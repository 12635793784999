import './Confetti.css'

const Confetti = () => {
    const numberOfItems = [...Array(150)].map((item, i) => i)
    
    return (
        <div className='confetti'>
            { numberOfItems.map((item, i) =>
                (<div className={`confetti__item confetti__item${i}`} key={i}></div>)
            )}
        </div>
    )
}

export default Confetti