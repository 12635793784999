// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/like.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".congrats {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: var(--space-3xl);\n}\n.congrats__image {\n    width: 150px;\n    aspect-ratio: 834/950;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center / 100% no-repeat;\n}\n.congrats__text {}\n.congrats__header {\n    margin-top: 0;\n    margin-bottom: var(--space-2xs);\n}\n.congrats__description {\n    max-width: 30ch;\n    margin-top: 0;\n    margin-bottom: var(--space-xl);\n}", "",{"version":3,"sources":["webpack://./src/pages/ColorPickle/colorPickleMatrix.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;AACzB;AACA;IACI,YAAY;IACZ,qBAAqB;IACrB,2EAA0E;AAC9E;AACA,iBAAiB;AACjB;IACI,aAAa;IACb,+BAA+B;AACnC;AACA;IACI,eAAe;IACf,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".congrats {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: var(--space-3xl);\n}\n.congrats__image {\n    width: 150px;\n    aspect-ratio: 834/950;\n    background: url('../../../public/images/like.png') center / 100% no-repeat;\n}\n.congrats__text {}\n.congrats__header {\n    margin-top: 0;\n    margin-bottom: var(--space-2xs);\n}\n.congrats__description {\n    max-width: 30ch;\n    margin-top: 0;\n    margin-bottom: var(--space-xl);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
