const getTopNumbers = ({solution, numberOfColumns, numberOfRows}) => {
    let draftNumbers = [...Array()]
    let numberOfCells = 0

    // going through the whole matrix and counting all the color lines
    for(let x = 0; x < numberOfColumns; x++) {
        let newCol = [...Array()]
        let help = 0
        
        for(let y = 0; y < numberOfRows; y++) {
            // if it's color cell
            if(solution[y][x] != 0) {
                // if it's not the first cell in a row
                if(help != 0 && solution[y][x] == solution[y-1][x]) {
                    help++
                // if it's another color bordered with previous line
                } else if(help != 0 && solution[y][x] != solution[y-1][x]) {
                    newCol.push([help, solution[y-1][x]])
                    help = 1
                // if it's the same color
                } else help++
            
                // if it's en empty cell bordered with previous line
            } else if (help != 0) {
                newCol.push([help, solution[y-1][x]])
                help = 0
            }
        
        }
        // if this column ends with the colored line
        if(help != 0) newCol.push([help, solution[numberOfRows - 1][x]])
        draftNumbers.push(newCol)

        // counting the longest line
        if(newCol.length > numberOfCells) numberOfCells = newCol.length
    }

    let topNumbers = [...Array(numberOfCells)].map(x => [...Array(numberOfColumns)].map(y => ''))
    let clearTopNumbers = [...Array(numberOfColumns)].map(x => [])

    // preparing the matrix for markup
    for(let i = 0; i < numberOfColumns; i++) {
        while(draftNumbers[i].length < numberOfCells) {
            draftNumbers[i].unshift([0, ''])
        }
    }
    
    // rotating matrix on 90deg
    for(let y = 0; y < numberOfCells; y++) {
        for(let x = 0; x < draftNumbers.length; x++) {
            draftNumbers[x][y].push(x)
            draftNumbers[x][y].push(y)
            topNumbers[y][x] = draftNumbers[x][y]
            
            if(draftNumbers[x][y][0] > 0)
                clearTopNumbers[x].push(topNumbers[y][x])
        }
    }

    return {'clear': clearTopNumbers, 'markup': topNumbers}
}

export default getTopNumbers