const getLeftNumbers = ({solution, numberOfColumns, numberOfRows}) => {
    let clearLeftNumbers = [...Array(numberOfRows)].map(x => [])
    let leftNumbers = [...Array()]
    let draftNumbers = [...Array()]
    let numberOfCells = 0

    // going through the whole matrix and counting all the color lines
    for(let y = 0; y < numberOfRows; y++) {
        let newCol = [...Array()]
        let help = 0

        for(let x = 0; x < numberOfColumns; x++) {
            // if it's color cell
            if(solution[y][x] != 0) {
                // if it's not the first cell in a row
                if(help != 0 && solution[y][x] == solution[y][x-1]) {
                    help++
                // if it's another color bordered with previous line
                } else if(help != 0 && solution[y][x] != solution[y][x-1]) {
                    newCol.push([help, solution[y][x-1]])
                    help = 1
                // if it's the same color
                } else help++
            
            // if it's en empty cell bordered with previous line
            } else if (help != 0) {
                newCol.push([help, solution[y][x-1]])
                help = 0
            }
        }

        // if this column ends with the colored line
        if(help != 0) newCol.push([help, solution[y][numberOfColumns-1]])
        leftNumbers.push(newCol)

        // counting the longest line
        if(newCol.length > numberOfCells) numberOfCells = newCol.length
    }
    draftNumbers = leftNumbers

    // preparing the matrix for markup
    for(let i = 0; i < numberOfRows; i++) {
        while(leftNumbers[i].length < numberOfCells) {
            leftNumbers[i].unshift([0, ''])
        }
    }
    // add coordinates
    for(let x = 0; x < numberOfCells; x++) {
        for(let y = 0; y < draftNumbers.length; y++) {
            leftNumbers[y][x].push(x)
            leftNumbers[y][x].push(y)
            
            if(leftNumbers[y][x][0] > 0)
                clearLeftNumbers[y].push(leftNumbers[y][x])
        }
    }
    return {'clear': clearLeftNumbers, 'markup': leftNumbers}
}

export default getLeftNumbers