import { useState, useEffect } from 'react'
import axios from 'axios'

import User from '../../components/User/User'
import Button from '../../components/Button/Button'
import IconLike from '../../components/Icon/IconLike'
import IconLiked from '../../components/Icon/IconLiked'

const BWSolved = ({userID, pickleID, pickleName, authorID, authorName, authorPhoto, solution, puzzleColumns}) => {
    const [isLiked, setIsLiked] = useState(false)

    const getUserLikes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-user`, {
                params: {user_id: userID}
            })
            if(response.data) {
                if(response.data['pickles_liked'].includes(pickleID)) setIsLiked(true)
                else setIsLiked(false)
            } else return false
        } catch (error) {
            console.log(error)
        }
    }

    const addLike = async () => {
        axios.put(`${process.env.REACT_APP_SERVER}/add-like`, {
            params: {pickle_id: pickleID, user_id: userID}
        })
    }
    const removeLike = async () => {
        axios.put(`${process.env.REACT_APP_SERVER}/remove-like`, {
            params: {pickle_id: pickleID, user_id: userID}
        })
    }

    function onLike() {
        setIsLiked(true)
        addLike()
    }
    function onDislike() {
        setIsLiked(false)
        removeLike()
    }

    useEffect(() => {
        getUserLikes()
    }, [])

    return (
        <>
            <div className='congrats'>
                <div className='congrats__image'></div>
                <div>
                    <h1 className="congrats__header text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold">you solved it</h1>
                    <p className="congrats__description text text_size-xl text_view-primary text_lineHeight-xs text_weight-normal">this is { pickleName }. do you like this pickle?</p>
                    { !isLiked &&
                        <Button view={'secondary'} icon={<IconLike />} text={'I like it'} onClick={onLike} />
                    }
                    { isLiked &&
                        <Button view={'primary'} icon={<IconLiked />} text={"I don't like it"} onClick={onDislike} />
                    }
                </div>
            </div>
        
            <div className='content__pickleSolution'>
                <div className='pickleMatrix pickleMatrix_win' style={{ "--puzzle-cells": puzzleColumns }}>
                    <div className='pickleMatrix__puzzle'>
                        {
                            solution.map((row, x) => (
                                <div className='pickleMatrix__row' key={x}>
                                    {
                                        row.map((cell, y) => (
                                            <div className={`pickleMatrix__cell`} color={solution[x][y] > 0 ? solution[x][y] : ''} key={y}></div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default BWSolved