import axios from 'axios'

const getAuthor = async ({id, setAuthor}) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-user`, {
            params: {user_id: id}
        })
        if(response.data) {
            setAuthor(response.data)
        } else return false
    } catch (error) {
        console.log(error)
    }
}

export default getAuthor