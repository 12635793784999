import axios from 'axios'
import { useState, useEffect } from 'react'

import './Leaderboard.css'

import Layout from '../../components/Layout/Layout'
import User from '../../components/User/User'

const Leaderboard = () => {
    const [users, setUsers] = useState(null)
    const [amount, setAmount] = useState(null)
    
    const getLeaderboard = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-leaderboard`, {})
            if(response.data) {
                let array = response.data.sort((a, b) => b['score'] - a['score'])
                setUsers(array)
            } else return false
        } catch (error) {
            console.log(error)
        }
    }
    const getAmount = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-amount-of-pickles`, {})
            if(response.data) {
                setAmount(response.data.length)
            } else return false
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getLeaderboard()
        getAmount()
    }, [])
    
    return (
        <Layout width={'s'} page={'leaderboard'}>
            <h1 className="content__header text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold">leaderboard</h1>
            { users != null  && users.length > 0 && (
                <div className='leaderboard'>
                <div className='leaderboard__row leaderboard__header'>
                    <div className='text text_size-s text_view-secondary text_align-center text_weight-bold'></div>
                    <div className='text text_size-s text_view-secondary text_align-right text_weight-bold'>solved</div>
                    <div className='text text_size-s text_view-secondary text_align-center text_weight-bold'>b&w</div>
                    <div className='text text_size-s text_view-secondary text_align-center text_weight-bold'>color</div>
                    <div className='text text_size-s text_view-secondary text_align-center text_weight-bold'>easy</div>
                    <div className='text text_size-s text_view-secondary text_align-center text_weight-bold'>medium</div>
                    <div className='text text_size-s text_view-secondary text_align-center text_weight-bold'>hard</div>
                    <div className='text text_size-s text_view-secondary text_align-center text_weight-bold'>score</div>
                </div>
                { users.map((user, key) => {
                    if(user['id'] != 'mrpickles' && user['id'] != 'b929ae8d-1dae-428c-b5cf-4125db6fad5b')
                        return (
                            <div className='leaderboard__row' key={key}>
                                <User size="m" showName={true} userPhoto={user['photo']} userName={user['name']} userID={user['id']} />
                                <div className='text text_size-m text_view-primary text_align-right'>{user['pickles_solved'].length} / {amount}</div>
                                <div className='text text_size-m text_view-primary text_align-center'>{user['bw_pickles'] > 0 ? user['bw_pickles'] : 0}</div>
                                <div className='text text_size-m text_view-primary text_align-center'>{user['color_pickles'] > 0 ? user['color_pickles'] : 0}</div>
                                <div className='text text_size-m text_view-success text_align-center'>{user['easy_pickles']}</div>
                                <div className='text text_size-m text_view-warning text_align-center'>{user['medium_pickles']}</div>
                                <div className='text text_size-m text_view-alert text_align-center'>{user['hard_pickles']}</div>
                                <div className='text text_size-m text_view-primary text_align-center text_weight-bold'>{user['score']}</div>
                            </div>
                        )
                })}
                </div>
            )}
        </Layout>
    )
}

export default Leaderboard