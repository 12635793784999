import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <p className="footer__copyright text text_size-s text_view-secondary">©2022 pickles. all rights reserved  ~  made with love and coffee by <a href='https://twitter.com/julia_miocene' className="text text_size-s text_view-link">Julia Miocene</a></p>
            <a href='/privacy-and-terms' className="text text_size-s text_view-link">privacy and terms</a>
            <a href='mailto:pickles.puzzles@gmail.com' className="text text_size-s text_view-link">contact us</a>
            <a href="https://twitter.com/pickles_puzzles" className="text text_size-s text_view-link footer__socialLink">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.03169 14.5004C11.0694 14.5004 14.3718 9.49823 14.3718 5.16029C14.3718 5.01823 14.3718 4.87679 14.3623 4.73598C15.0047 4.27126 15.5593 3.69592 16 3.03679C15.4009 3.30226 14.7653 3.47632 14.1146 3.55326C14.7997 3.14313 15.3128 2.49757 15.5577 1.73757C14.9134 2.11992 14.2084 2.38942 13.4733 2.53438C12.8526 1.87435 11.9857 1.49963 11.0798 1.49963C9.27719 1.49963 7.79397 2.98288 7.79397 4.78538C7.79397 5.03545 7.82253 5.28473 7.87903 5.52829C5.24097 5.39607 2.77966 4.14826 1.11359 2.09854C0.247219 3.58998 0.6955 5.52279 2.12991 6.4806C1.60756 6.46513 1.09644 6.3242 0.64 6.06973V6.11132C0.640437 7.66801 1.74772 9.02104 3.27359 9.32926C2.79034 9.46104 2.28319 9.48029 1.79138 9.38557C2.22034 10.7195 3.45734 11.6389 4.85825 11.6653C3.69606 12.5786 2.25959 13.0747 0.781438 13.0733C0.52025 13.0728 0.259313 13.0569 0 13.0259C1.50106 13.9892 3.24813 14.5003 5.03169 14.4979" fill="#1DA1F2"/>
                </svg>
                follow us
            </a>
        </footer>
    )
}

export default Footer