import { Navigate } from 'react-router-dom'

import './Layout.css'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Login = ({ page, width, contentClassName = '', style, children }) => {
    const user_id = localStorage.getItem('user_id')
    const widthClassName = width == 's' && 'page__content-size-s' || 
                           width == 'xs' && 'page__content-size-xs' ||
                           width == 'm' && 'page__content-size-m' ||
                           width == 'full' && 'page__content-size-full'

    const ifForbidden = !user_id && page != 'login' && page != 'bw' && page != 'color' && page != 'tutorial' && page != 'policy' && page != 'notfound'

    return (
        <div className='page' style={style}>
            { ifForbidden && <Navigate replace to="/login" /> }
            { user_id && page == 'login' && <Navigate replace to="/bw" /> }
            
            <Header page={page} />

            <div className={`page__content ${widthClassName}`}>
                <main className={`page__main ${contentClassName}`}>
                    { children }
                </main>
                
                { page != 'bw-pickle' && page != 'color-pickle' &&
                    <Footer />
                }
            </div>
        </div>
    )
}

export default Login