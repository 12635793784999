import './User.css'

const User = ({size, showName, userID, userName, userPhoto}) => {
  return (
    <div className={"user user_size-" + size}>
        <div style={{backgroundImage: `url(${userPhoto})`}} alt={"photo of " + userName} className="user__photo"></div>
        { showName ?
            <div className="user__name">{ userName }</div> : ''
        }
        <a href={`/profile?id=${userID}`} className='user__link'></a>
    </div>
  )
}
export default User;