import Layout from '../../components/Layout/Layout'
import './Policy.css'

const Policy = () => {
    return (
        <Layout width={'s'} page={'policy'}>
            <div className='policyPage'>
                <div className='policyPage__content'>
                    <h1 className='text text_size-4xl text_view-primary text_lineHeight-2xs text_weight-normal'>privacy policy and terms of use</h1>
                    
                    <p className='text text_size-l text_view-primary text_lineHeight-m text_weight-light'>let's try to keep things simple</p>
                    <p className='text text_size-l text_view-primary text_lineHeight-m text_weight-light'>pickles is an online logic game where players solve puzzles to get the picture.</p>

                    <h2 className='text text_size-3xl text_view-primary text_lineHeight-xs text_weight-normal'>data</h2>
                    <p className='text text_size-l text_view-primary text_lineHeight-m text_weight-light'>when you login, pickles stores your name, email, ID and profile picture. your profile picture and name is public on the website.</p>
                    <p className='text text_size-l text_view-primary text_lineHeight-m text_weight-light'>when you start playing, pickles stores your score data, solved pickles and likes.</p>
                    <p className='text text_size-l text_view-primary text_lineHeight-m text_weight-light'>your score data, solved pickles and profile information is public for everyone to see.</p>
                    <p className='text text_size-l text_view-primary text_lineHeight-m text_weight-light'>your email and ID is only used for authentication and is not shared with anyone, at all.</p>
                    <p className='text text_size-l text_view-primary text_lineHeight-m text_weight-light'>this website doesn't use Google Analytics and any other tool at all.</p>
                    
                    <h2 className='text text_size-3xl text_view-primary text_lineHeight-xs text_weight-normal'>security</h2>
                    <p className='text text_size-l text_view-primary text_lineHeight-m text_weight-light'>pickles website works strictly over HTTPS to provide you a secured environment.</p>
                    <p className='text text_size-l text_view-primary text_lineHeight-m text_weight-light'>your submitted source codes are secured and no other player can access them.</p>
                    <p className='text text_size-l text_view-primary text_lineHeight-m text_weight-light'>last Updated: October 10 2022</p>
                </div>
            </div>
        </Layout>
    )
}

export default Policy