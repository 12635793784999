import './App.css'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Test from './pages/Test'

import Home from './pages/Home'
import Login from './pages/Login/Login'
import BW from './pages/BW'
import BWPickle from './pages/BWPickle/BWPickle'
import Color from './pages/Color'
import ColorPickle from './pages/ColorPickle/ColorPickle'
import Tutorial from './pages/Tutorial/Tutorial'
import Profile from './pages/Profile/Profile'
import Policy from './pages/Policy/Policy'
import NotFound from './pages/NotFound/NotFound'
import Leaderboard from './pages/Leaderboard/Leaderboard'

import AdminAllP from './admin/AdminAllP'
import AdminColor from './admin/AdminColor'
import AdminBW from './admin/AdminBW'
import AdminBWPickle from './admin/AdminBWPickle'
import AdminColorPickle from './admin/AdminColorPickle'

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                
                <Route path='/login' element={<Login />} />
                
                <Route path='/tutorial' element={<Tutorial />} />
                
                <Route path='/bw' element={<BW />} />
                <Route path='/bw-pickle' element={<BWPickle />} />
                
                <Route path='/color' element={<Color />} />
                <Route path='/color-pickle' element={<ColorPickle />} />
                
                <Route path='/profile' element={<Profile />} />
                
                <Route path='/leaderboard' element={<Leaderboard />} />
                
                <Route path='/admincolor' element={<AdminColor />} />
                <Route path='/admincolor-pickle' element={<AdminColorPickle />} />
                <Route path='/adminbw' element={<AdminBW />} />
                <Route path='/adminbw-pickle' element={<AdminBWPickle />} />
                <Route path='/admin-all' element={<AdminAllP />} />
                
                <Route path='/privacy-and-terms' element={<Policy />} />
                
                <Route path='/test' element={<Test />} />
                
                <Route path='/*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App