import { useState, useEffect } from 'react'
import axios from 'axios'

import User from '../../components/User/User'
import Button from '../../components/Button/Button'
import IconLike from '../../components/Icon/IconLike'
import IconLiked from '../../components/Icon/IconLiked'

const ColorSolved = ({userID, pickle, authorName, authorPhoto}) => {
    const [isLiked, setIsLiked] = useState(false)

    const getUserLikes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-user`, {
                params: {user_id: userID}
            })
            if(response.data) {
                if(response.data['pickles_liked'].includes(pickle['id'])) setIsLiked(true)
                else setIsLiked(false)
            } else return false
        } catch (error) {
            console.log(error)
        }
    }

    const addLike = async () => {
        axios.put(`${process.env.REACT_APP_SERVER}/add-like`, {
            params: {pickle_id: pickle['id'], user_id: userID}
        })
    }
    const removeLike = async () => {
        axios.put(`${process.env.REACT_APP_SERVER}/remove-like`, {
            params: {pickle_id: pickle['id'], user_id: userID}
        })
    }

    function onLike() {
        setIsLiked(true)
        addLike()
    }
    function onDislike() {
        setIsLiked(false)
        removeLike()
    }

    useEffect(() => {
        getUserLikes()
    }, [])

    return (
        <main className="content" style={{ "--cell-color-1": pickle['colors'][0],
                                           "--cell-color-2": pickle['colors'][1],
                                           "--cell-color-3": pickle['colors'][2],
                                           "--cell-color-4": pickle['colors'][3],
                                           "--cell-color-5": pickle['colors'][4],
                                           "--cell-color-6": pickle['colors'][5],
                                           "--cell-color-7": pickle['colors'][6],
                                           "--cell-color-8": pickle['colors'][7],
                                           "--cell-color-9": pickle['colors'][8] }}>
            <div className='congrats'>
                <div className='congrats__image'></div>
                <div>
                    <h1 className="congrats__header text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold">you solved it</h1>
                    <p className="congrats__description text text_size-xl text_view-primary text_lineHeight-xs text_weight-normal">this is { pickle['name'] }. do you like this pickle?</p>
                    { !isLiked &&
                        <Button view={'secondary'} icon={<IconLike />} text={'I like it'} onClick={onLike} />
                    }
                    { isLiked &&
                        <Button view={'primary'} icon={<IconLiked />} text={"I don't like it"} onClick={onDislike} />
                    }
                </div>
            </div>
        
            <div className='content__pickleSolution'>
                <div className='pickleMatrix pickleMatrix-color pickleMatrix_win theme-light' style={{ "--puzzle-cells": pickle['columns'] }}>
                    <div className='pickleMatrix__puzzle'>
                        {
                            pickle['solution'].map((row, x) => (
                                <div className='pickleMatrix__row' key={x}>
                                    {
                                        row.map((cell, y) => (
                                            <div className={`pickleMatrix__cell`} color={cell > 0 ? cell : ''} key={y}></div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ColorSolved