const getNumberOfCheckedCells = ({solution, numberOfColumns, numberOfRows}) => {
    let numberOfCheckedCells = 0

    for(let y = 0; y < numberOfRows; y++) {
        for(let x = 0; x < numberOfColumns; x++) {
            if(solution[y][x] !== '') numberOfCheckedCells++
        }
    }

    return numberOfCheckedCells
}

export default getNumberOfCheckedCells