import showCursorNumber from './showCursorNumber'

const drawLine = (x, y, selectedCells, matrix, pickle) => {
    let cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')
    
    if(selectedCells != null) {
        const startX = selectedCells['firstCell'][0] < x ? selectedCells['firstCell'][0] : x
        const endX = selectedCells['firstCell'][0] > x ? selectedCells['firstCell'][0] : x
        const startY = selectedCells['firstCell'][1] < y ? selectedCells['firstCell'][1] : y
        const endY = selectedCells['firstCell'][1] > y ? selectedCells['firstCell'][1] : y
        const color = selectedCells['color']
        
        const length = Math.abs(startX - endX) + 1
        const height = Math.abs(startY - endY) + 1
        
        // show previous matrix state
        if(selectedCells['array']) {
            selectedCells['array'].map(cell => {
                const block = document.querySelector(`.pickleMatrix__puzzle [axisx='${cell[0]}'][axisy='${cell[1]}']`)
                
                if(selectedCells['isSkipped'] == true) block.setAttribute('color', 0)
                else if(selectedCells['isEmpty'] == true) block.setAttribute('color', '')
                
                block.setAttribute('color', cell[2])
            })
        }

        // save this matrix state
        selectedCells['array'] = [...Array()]
        for(let iX = startX; iX < endX + 1; iX++) {
            for(let iY = startY; iY < endY + 1; iY++) {
                const cell = document.querySelector(`.pickleMatrix__puzzle [axisx='${iX}'][axisy='${iY}']`)
                let previousColor = cell.getAttribute('color')
                
                selectedCells['array'].push([iX, iY, previousColor])
                cell.setAttribute('color', color)
            }
        }
        
        // show popup with number
        if(!selectedCells['isEmpty'] && !selectedCells['isSkipped']) {
            cursorNumber.classList.remove('pickleMatrix__cursorNumber-hidden')
            showCursorNumber({length, height, x, y, selectedCells, matrix, pickle})
        }
    }
}

export default drawLine