// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/404-light.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./images/404-dark.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notfound {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n.notfound__image {\n    display: block;\n    width: 512px; height: 237px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    margin-bottom: var(--space-2xl);\n}\n@media (prefers-color-scheme: dark) {\n    .notfound__image { background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "); }\n}", "",{"version":3,"sources":["webpack://./src/pages/NotFound/NotFound.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,cAAc;IACd,YAAY,EAAE,aAAa;IAC3B,mDAAyC;IACzC,+BAA+B;AACnC;AACA;IACI,mBAAmB,mDAAwC,EAAE;AACjE","sourcesContent":[".notfound {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n.notfound__image {\n    display: block;\n    width: 512px; height: 237px;\n    background: url('./images/404-light.svg');\n    margin-bottom: var(--space-2xl);\n}\n@media (prefers-color-scheme: dark) {\n    .notfound__image { background: url('./images/404-dark.svg'); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
