import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'

import './Profile.css'

import Layout from '../../components/Layout/Layout'
import PicklePreview from '../../components/PicklePreview/PicklePreview'
import Button from '../../components/Button/Button'
import IconLogout from '../../components/Icon/IconLogout'

const Profile = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const profileID = urlParams.get('id')
    const [lickedPickles, setLickedPickles] = useState(null)
    const [pickles, setPickles] = useState(null)
    const [user, setUser] = useState(null)
    const [user_id, setUserID] = useState(localStorage.getItem('user_id'))
    let page = 0

    const getLickedPickles = async () => {
        try {
            const userResponse = await axios.get(`${process.env.REACT_APP_SERVER}/get-user`, {
                params: {user_id: user_id}
            })
            if(userResponse.data) {
                setLickedPickles(userResponse.data['pickles_liked'])

                return true
            } else return false
        } catch (error) {
            console.log(error)
        }
    }
    const getUserInfo = async () => {
        try {
            const userResponse = await axios.get(`${process.env.REACT_APP_SERVER}/get-user`, {
                params: {user_id: profileID}
            })
            if(userResponse.data) {
                setUser(userResponse.data)
                
                manageBadges('badgeCount', userResponse.data['score'])
                manageBadges('badgeBW', userResponse.data['bw_pickles'])
                manageBadges('badgeColor', userResponse.data['color_pickles'])
                manageBadges('badgeEasy', userResponse.data['easy_pickles'])
                manageBadges('badgeMedium', userResponse.data['medium_pickles'])
                manageBadges('badgeHard', userResponse.data['hard_pickles'])
                return true
            } else return false
        } catch (error) {
            console.log(error)
        }
    }
    const getPickles = async () => {
        try {
            const pickleResponse = await axios.get(`${process.env.REACT_APP_SERVER}/get-solved-pickles`, {
                params: {skip: page, user_id: profileID}
            })
            if(pickleResponse.data) {
                if(page >= 1)
                    setPickles(pickles => [...pickles, ...pickleResponse.data])
                else setPickles(pickleResponse.data)
                
                return true
            } else return false
        } catch (error) {
            console.log(error)
        }
    }

    function onLogout() {
        localStorage.clear()
        sessionStorage.clear()
        setUserID(null)
    }
    function manageBadges(badgeID, number) {
        const badgeBlock = document.getElementById(badgeID)

        if(!number || number == 0) {
            badgeBlock.classList.add('profile__badgeImage-stage0')
            badgeBlock.querySelector('.progressBox__count').style.width = 0 + '%'
            badgeBlock.querySelector('.profile__badgeImage').setAttribute('number', '')
            badgeBlock.querySelector('.progressBox__text').innerHTML = `0 / 1`
        } else if(number < 10) {
            badgeBlock.classList.add('profile__badgeImage-stage1')
            badgeBlock.querySelector('.progressBox__count').style.width = (number / 10) * 100 + '%'
            badgeBlock.querySelector('.profile__badgeImage').setAttribute('number', 1)
            badgeBlock.querySelector('.progressBox__text').innerHTML = `${number} / 10`
        } else if(number < 20) {
            badgeBlock.classList.add('profile__badgeImage-stage2')
            badgeBlock.querySelector('.progressBox__count').style.width = (number / 20) * 100 + '%'
            badgeBlock.querySelector('.profile__badgeImage').setAttribute('number', 2)
            badgeBlock.querySelector('.progressBox__text').innerHTML = `${number} / 20`
        } else if(number < 50) {
            badgeBlock.classList.add('profile__badgeImage-stage3')
            badgeBlock.querySelector('.progressBox__count').style.width = (number / 50) * 100 + '%'
            badgeBlock.querySelector('.profile__badgeImage').setAttribute('number', 3)
            badgeBlock.querySelector('.progressBox__text').innerHTML = `${number} / 50`
        } else if(number < 150) {
            badgeBlock.classList.add('profile__badgeImage-stage4')
            badgeBlock.querySelector('.progressBox__count').style.width = (number / 150) * 100 + '%'
            badgeBlock.querySelector('.profile__badgeImage').setAttribute('number', 4)
            badgeBlock.querySelector('.progressBox__text').innerHTML = `${number} / 150`
        } else if(number < 500) {
            badgeBlock.classList.add('profile__badgeImage-stage5')
            badgeBlock.querySelector('.progressBox__count').style.width = (number / 500) * 100 + '%'
            badgeBlock.querySelector('.profile__badgeImage').setAttribute('number', 5)
            badgeBlock.querySelector('.progressBox__text').innerHTML = `${number} / 500`
        } else if(number < 1000) {
            badgeBlock.classList.add('profile__badgeImage-stage6')
            badgeBlock.querySelector('.progressBox__count').style.width = (number / 1000) * 100 + '%'
            badgeBlock.querySelector('.profile__badgeImage').setAttribute('number', 6)
            badgeBlock.querySelector('.progressBox__text').innerHTML = `${number} / 1000`
        } else if(number < 2000) {
            badgeBlock.classList.add('profile__badgeImage-stage7')
            badgeBlock.querySelector('.progressBox__count').style.width = (number / 2000) * 100 + '%'
            badgeBlock.querySelector('.profile__badgeImage').setAttribute('number', 7)
            badgeBlock.querySelector('.progressBox__text').innerHTML = `${number} / 2000`
        } else if(number < 5000) {
            badgeBlock.classList.add('profile__badgeImage-stage8')
            badgeBlock.querySelector('.progressBox__count').style.width = (number / 5000) * 100 + '%'
            badgeBlock.querySelector('.profile__badgeImage').setAttribute('number', 8)
            badgeBlock.querySelector('.progressBox__text').innerHTML = `${number} / 5000`
        }
    }

    useEffect(() => {
        let mainSection = document.querySelector('.page__content')
        
        mainSection.addEventListener('scroll', e => {
            if((window.innerHeight + e.target.scrollTop + 1) >= e.target.scrollHeight) {
                page++
                getPickles()
            }
        })

        getUserInfo()
        getLickedPickles()
        getPickles()
    }, [])

    return (
        <Layout page={'profile'} width={'m'}>
            { !user_id && <Navigate replace to="/bw" /> }

            {user != null && (
                <>
                <div className='profile__userheader'>
                    <div style={{backgroundImage: `url(${user['photo']})`}} alt={"photo of " + user['name']} className="profile__userphoto"></div>
                    <h1 className="text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold profile__userheadername">{user['name']}</h1>
                    
                    { user_id == user['id'] &&
                        <Button view={'clear'} icon={<IconLogout />} iconOnly={true} onClick={onLogout} />
                    }
                </div>
                <div className='profile__badges'>
                    <div id='badgeCount' className='profile__badge'>
                        <div className='profile__badgeImage'>
                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M49.8366 96.2937C37.7456 96.2937 7.00039 71.4594 7.00039 22.1203C7.00039 10.0328 28.4185 3.00008 49.8366 3.00008C71.2547 3.00008 92.6729 10.802 92.6729 22.1203C92.6729 72.2286 61.9276 96.2937 49.8366 96.2937Z" fill="var(--badge-color-4)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M26.9912 81.5988C16.4856 69.3705 7 49.5255 7 22.1202C7 10.0327 28.4181 3 49.8362 3C71.2543 3 92.6725 10.8019 92.6725 22.1202C92.6725 49.7835 83.3019 69.5093 72.8727 81.6305C65.5269 83.3494 57.8694 84.2585 49.9996 84.2585C42.0815 84.2585 34.3783 83.3382 26.9912 81.5988Z" fill="var(--badge-color-3)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.0879 47.9761C8.14814 40.2742 7 31.6548 7 22.1202C7 10.0327 28.4181 3 49.8362 3C71.2543 3 92.6725 10.8019 92.6725 22.1202C92.6725 31.7342 91.5407 40.3894 89.626 48.0999C77.4755 53.3495 64.0773 56.2585 49.9996 56.2585C35.8117 56.2585 22.3139 53.3038 10.0879 47.9761Z" fill="var(--badge-color-2)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M92.1094 18.9868C79.3112 24.9368 65.0437 28.2585 50.0005 28.2585C34.8258 28.2585 20.4406 24.8785 7.55664 18.8303C11.0491 8.76628 30.4431 3 49.8371 3C69.216 3 88.595 9.38705 92.1094 18.9868Z" fill="var(--badge-color-1)"/>
                            </svg>
                        </div>
                        <div className='profile__badgeName text text_view-primary text_size-m text_weight-bold text_align-center'>total score</div>
                        <div className='progressBox'>
                            <div className='progressBox__count'></div>
                            <div className='progressBox__text'></div>
                        </div>
                    </div>
                    <div id='badgeBW' className='profile__badge'>
                        <div className='profile__badgeImage'>
                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M95 50C95 74.8528 74.8528 95 50 95C25.1472 95 5 74.8528 5 50C5 25.1472 25.1472 5 50 5C74.8528 5 95 25.1472 95 50Z" fill="var(--badge-color-4)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.8302 78.0756C8.67826 70.3792 5 60.6191 5 50C5 25.1472 25.1472 5 50 5C74.8528 5 95 25.1472 95 50C95 61.2799 90.8498 71.5904 83.9931 79.4879C73.7563 83.0242 62.766 84.9448 51.3271 84.9448C38.4478 84.9448 26.137 82.51 14.8302 78.0756Z" fill="var(--badge-color-3)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.20312 45.6956C7.36995 22.862 26.599 5 49.9999 5C73.8372 5 93.3455 23.5343 94.9001 46.978C81.7296 53.3638 66.9465 56.9448 51.3271 56.9448C34.691 56.9448 19.0037 52.8825 5.20312 45.6956Z" fill="var(--badge-color-2)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M85.8667 22.8194C75.1017 26.7815 63.4669 28.9448 51.3269 28.9448C38.4127 28.9448 26.0702 26.4968 14.7383 22.0395C22.9817 11.6576 35.7132 5 49.9998 5C64.6409 5 77.6489 11.9922 85.8667 22.8194Z" fill="var(--badge-color-1)"/>
                            </svg>
                        </div>
                        <div className='profile__badgeName text text_view-primary text_size-m text_weight-bold text_align-center'>b&w pickles</div>
                        <div className='progressBox'>
                            <div className='progressBox__count'></div>
                            <div className='progressBox__text'>{user['bw_pickles']} / 100</div>
                        </div>
                    </div>
                    <div id='badgeColor' className='profile__badge'>
                        <div className='profile__badgeImage'>
                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M95 50C95 74.8528 74.8528 95 50 95C25.1472 95 5 74.8528 5 50C5 25.1472 25.1472 5 50 5C74.8528 5 95 25.1472 95 50Z" fill="var(--badge-color-4)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M14.8302 78.0756C8.67826 70.3792 5 60.6191 5 50C5 25.1472 25.1472 5 50 5C74.8528 5 95 25.1472 95 50C95 61.2799 90.8498 71.5904 83.9931 79.4879C73.7563 83.0242 62.766 84.9448 51.3271 84.9448C38.4478 84.9448 26.137 82.51 14.8302 78.0756Z" fill="var(--badge-color-3)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.20312 45.6956C7.36995 22.862 26.599 5 49.9999 5C73.8372 5 93.3455 23.5343 94.9001 46.978C81.7296 53.3638 66.9465 56.9448 51.3271 56.9448C34.691 56.9448 19.0037 52.8825 5.20312 45.6956Z" fill="var(--badge-color-2)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M85.8667 22.8194C75.1017 26.7815 63.4669 28.9448 51.3269 28.9448C38.4127 28.9448 26.0702 26.4968 14.7383 22.0395C22.9817 11.6576 35.7132 5 49.9998 5C64.6409 5 77.6489 11.9922 85.8667 22.8194Z" fill="var(--badge-color-1)"/>
                            </svg>
                        </div>
                        <div className='profile__badgeName text text_view-primary text_size-m text_weight-bold text_align-center'>color pickles</div>
                        <div className='progressBox'>
                            <div className='progressBox__count'></div>
                            <div className='progressBox__text'>{user['color_pickles']} / 100</div>
                        </div>
                    </div>
                    <div id='badgeEasy' className='profile__badge'>
                        <div className='profile__badgeImage'>
                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M43.2652 2.77099C46.9599 -0.923666 52.9501 -0.923662 56.6448 2.77099L97.139 43.2652C100.834 46.9599 100.834 52.9501 97.139 56.6448L56.6448 97.139C52.9501 100.834 46.9599 100.834 43.2652 97.139L2.77099 56.6448C-0.923666 52.9501 -0.923662 46.9599 2.77099 43.2652L43.2652 2.77099Z" fill="var(--badge-color-4)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M27.3938 81.2676L2.77099 56.6448C-0.923666 52.9501 -0.923662 46.9599 2.77099 43.2652L43.2652 2.77099C46.9599 -0.923666 52.9501 -0.923662 56.6448 2.77099L97.139 43.2652C100.834 46.9599 100.834 52.9501 97.139 56.6448L72.2946 81.4892C65.2258 83.0736 57.8741 83.9097 50.3271 83.9097C42.4359 83.9097 34.758 82.9956 27.3938 81.2676Z" fill="var(--badge-color-3)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.37598 43.6847C2.50259 43.5419 2.63427 43.402 2.77101 43.2652L43.2653 2.77099C46.9599 -0.923666 52.9501 -0.923662 56.6448 2.77099L97.139 43.2652C97.3629 43.4891 97.5732 43.7214 97.7699 43.9611C83.6534 51.5832 67.4955 55.9097 50.3272 55.9097C32.9523 55.9097 16.6125 51.4785 2.37598 43.6847Z" fill="var(--badge-color-2)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M77.9267 24.0525C69.1604 26.5644 59.9011 27.9097 50.3276 27.9097C40.5437 27.9097 31.0881 26.5046 22.1514 23.8853L43.2657 2.77099C46.9603 -0.923666 52.9505 -0.923662 56.6452 2.77099L77.9267 24.0525Z" fill="var(--badge-color-1)"/>
                            </svg>
                        </div>
                        <div className='profile__badgeName text text_view-primary text_size-m text_weight-bold text_align-center'>easy pickles</div>
                        <div className='progressBox'>
                            <div className='progressBox__count'></div>
                            <div className='progressBox__text'>{user['easy_pickles']} / 100</div>
                        </div>
                    </div>
                    <div id='badgeMedium' className='profile__badge'>
                        <div className='profile__badgeImage'>
                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M45.4222 1.2675C48.3494 -0.422501 51.9558 -0.4225 54.8829 1.2675L89.5747 21.2968C92.5019 22.9868 94.3051 26.1101 94.3051 29.4901L94.3051 69.5487C94.3051 72.9287 92.5019 76.052 89.5747 77.742L54.8829 97.7713C51.9558 99.4613 48.3494 99.4613 45.4222 97.7713L10.7304 77.742C7.80321 76.052 6 72.9287 6 69.5487L6 29.4901C6 26.1101 7.80321 22.9868 10.7304 21.2968L45.4222 1.2675Z" fill="var(--badge-color-4)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.26837 74.2806C6.45112 72.8657 6 71.2393 6 69.5487V29.4901C6 26.1101 7.80321 22.9868 10.7304 21.2968L45.4222 1.2675C48.3494 -0.422501 51.9558 -0.4225 54.8829 1.2675L89.5747 21.2968C92.5019 22.9868 94.3051 26.1101 94.3051 29.4901V69.5487C94.3051 71.3322 93.8031 73.0441 92.8988 74.5117C79.9822 80.5976 65.552 84 50.3271 84C34.9106 84 20.3089 80.5114 7.26837 74.2806Z" fill="var(--badge-color-3)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M6 45.6635V29.4901C6 26.1101 7.80321 22.9868 10.7304 21.2968L45.4222 1.2675C48.3494 -0.422501 51.9558 -0.4225 54.8829 1.2675L89.5747 21.2968C92.5019 22.9868 94.3051 26.1101 94.3051 29.4901V45.8356C81.033 52.3451 66.1069 56 50.3271 56C34.4088 56 19.3593 52.2806 6 45.6635Z" fill="var(--badge-color-2)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M88.2775 20.5476C76.5754 25.3513 63.7608 28 50.3276 28C36.8163 28 23.9309 25.3204 12.1738 20.4637L45.4226 1.2675C48.3498 -0.422501 51.9562 -0.4225 54.8834 1.2675L88.2775 20.5476Z" fill="var(--badge-color-1)"/>
                            </svg>
                        </div>
                        <div className='profile__badgeName text text_view-primary text_size-m text_weight-bold text_align-center'>medium pickles</div>
                        <div className='progressBox'>
                            <div className='progressBox__count'></div>
                            <div className='progressBox__text'>{user['medium_pickles']} / 100</div>
                        </div>
                    </div>
                    <div id='badgeHard' className='profile__badge'>
                        <div className='profile__badgeImage'>
                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M46.2178 2.72016C48.536 1.75995 51.1406 1.75995 53.4588 2.72016L80.5958 13.9607C82.914 14.9209 84.7557 16.7627 85.716 19.0808L96.9565 46.2178C97.9167 48.536 97.9167 51.1406 96.9565 53.4588L85.716 80.5958C84.7557 82.914 82.914 84.7557 80.5958 85.716L53.4588 96.9565C51.1406 97.9167 48.536 97.9167 46.2178 96.9565L19.0808 85.716C16.7627 84.7557 14.9209 82.914 13.9607 80.5958L2.72016 53.4588C1.75995 51.1406 1.75995 48.536 2.72016 46.2178L13.9607 19.0808C14.9209 16.7627 16.7627 14.9209 19.0808 13.9607L46.2178 2.72016Z" fill="var(--badge-color-4)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.4239 76.8856L2.72016 53.4588C1.75995 51.1406 1.75995 48.536 2.72016 46.2178L13.9607 19.0808C14.9209 16.7627 16.7627 14.9209 19.0808 13.9607L46.2178 2.72016C48.536 1.75995 51.1406 1.75995 53.4588 2.72016L80.5958 13.9607C82.914 14.9209 84.7557 16.7627 85.7159 19.0808L96.9565 46.2178C97.9167 48.536 97.9167 51.1406 96.9565 53.4588L87.0572 77.3578C75.6865 81.8504 63.2951 84.3188 50.3271 84.3188C36.9118 84.3188 24.1134 81.6772 12.4239 76.8856Z" fill="var(--badge-color-3)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.37598 44.634L13.9605 19.0808C14.9207 16.7627 16.7624 14.9209 19.0806 13.9607L46.2176 2.72016C48.5358 1.75995 51.1404 1.75995 53.4586 2.72016L80.5956 13.9607C82.9138 14.9209 84.7555 16.7627 85.7157 19.0808L96.4754 45.0569C82.6689 52.2517 66.9729 56.3188 50.3269 56.3188C33.3576 56.3188 17.3755 52.0921 3.37598 44.634Z" fill="var(--badge-color-2)"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M86.7149 21.4924C75.438 25.8997 63.1651 28.3188 50.3272 28.3188C37.1698 28.3188 24.606 25.7778 13.0996 21.1597L13.9607 19.0808C14.9209 16.7627 16.7627 14.9209 19.0808 13.9607L46.2179 2.72016C48.536 1.75995 51.1407 1.75995 53.4588 2.72016L80.5958 13.9607C82.914 14.9209 84.7558 16.7627 85.716 19.0808L86.7149 21.4924Z" fill="var(--badge-color-1)"/>
                            </svg>
                        </div>
                        <div className='profile__badgeName text text_view-primary text_size-m text_weight-bold text_align-center'>hard pickles</div>
                        <div className='progressBox'>
                            <div className='progressBox__count'></div>
                            <div className='progressBox__text'>{user['hard_pickles']} / 100</div>
                        </div>
                    </div>
                </div>
                </>
            )}
            {pickles != null && lickedPickles != null && (
                <ul className="pickleList">
                    { pickles.map((item, key) => {
                        let isLiked = false;
                        if(lickedPickles.includes(item['id'])) {
                            isLiked = true
                        }
                        return ( 
                            <PicklePreview pickle={item} isLiked={isLiked} solved={true} key={key} />)
                    }) }
                </ul>
            )}
            {user == null && pickles == null && (
                <div className='loader'></div>
            )}
        </Layout>
    )
}

export default Profile