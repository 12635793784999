import { useState, useEffect } from 'react'
import axios from 'axios'

import Layout from '../../components/Layout/Layout'
import PicklePreview from '../../components/PicklePreview/PicklePreview'

import './Tutorial.css'

const Tutorial = () => {
    const [pickle, setPickle] = useState([])

    const getPickles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-pickles`, {
                params: {published: true, difficulty: 1}
            })
            if(response.data) {
                let array = []
                let ids = [Math.floor((Math.random()*response.data.length))]
                
                for(let i = 0; i < 2; i++) {
                    let num = Math.floor((Math.random()*response.data.length))

                    while(ids.includes(num))
                        num = Math.floor((Math.random()*response.data.length))
                    ids.push(num)
                }

                array.push(response.data[ids[0]])
                array.push(response.data[ids[1]])
                array.push(response.data[ids[2]])
                setPickle(array)

                return true
            } else return false
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getPickles()
    }, [])

    return (
        <Layout page={'tutorial'} width={'xs'} contentClassName={'tutorial'}>
            <div className='tutorial__logo'>pickles</div>
            <h1 className="tutorial__h1 text text_size-4xl text_view-primary text_lineHeight-xs text_weight-bold">tips & tricks</h1>
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>it’s a logic puzzle that uses numbers to reveal a hidden image. we have simple tutorials for you to understand pickles puzzles better.</p>
            <div className='tutorial__image tutorial__image-1'></div>

            <h2 className="tutorial__h2 text text_size-4xl text_view-primary text_lineHeight-xs text_weight-bold">rules</h2>
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>numbers shows now many cells should be colored, no matter if it’s a color pickle or a black&white one</p>
            <div className='tutorial__image tutorial__image-2'></div>
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>between each colored lines should be at least one cross. but only if they have the same color.</p>
            <div className='tutorial__image tutorial__image-3'></div>

            <h2 className="tutorial__h2 text text_size-4xl text_view-primary text_lineHeight-xs text_weight-bold">techniques</h2>
            <h3 className="tutorial__h3 text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold">intersection</h3>
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>find the intersection of the lines from the first and from the last cell and color this cells</p>
            <div className='tutorial__image tutorial__image-4'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>if you have several numbers, find the intersection for each of them separately</p>
            <div className='tutorial__image tutorial__image-5'></div>
            
            <h3 className="tutorial__h3 text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold">lack of space</h3>
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>if you have crosses, check how many space between them do you have. is it enough?</p>
            <div className='tutorial__image tutorial__image-6'></div>
            
            <h3 className="tutorial__h3 text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold">inaccessibility</h3>
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>place crosses in the cells, which can’t be colored</p>
            <div className='tutorial__image tutorial__image-7'></div>
            
            <h3 className="tutorial__h3 text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold">cell union</h3>
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>if you have just one number and a few colored cells — unite them!</p>
            <div className='tutorial__image tutorial__image-8'></div>
            
            <h3 className="tutorial__h3 text text_size-3xl text_view-primary text_lineHeight-xs text_weight-bold">pushing away from the borders</h3>
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>if there are colored cells close to borders or crosses, "push off" from them</p>
            <div className='tutorial__image tutorial__image-9'></div>

            <h2 className="tutorial__h2 text text_size-4xl text_view-primary text_lineHeight-xs text_weight-bold">let’s try to solve one</h2>
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>first of all, see if you have numbers equal to the length or height of the matrix and fill in these cells</p>
            <div className='tutorial__image tutorial__image-10'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>then find the sum of numbers equal to the length or height of the matrix and fill in these cells. and don’t forget to place crosses between lines</p>
            <div className='tutorial__image tutorial__image-11'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>find the intersections for the largest numbers in this matrix</p>
            <div className='tutorial__image tutorial__image-12'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>put crosses in the cells that you can’t fill in</p>
            <div className='tutorial__image tutorial__image-13'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>find unfinished lines bordering a border or cross and finish them</p>
            <div className='tutorial__image tutorial__image-14'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>when all required cells in a row or column are filled in, the empty cells are filled with crosses automaticly</p>
            <div className='tutorial__image tutorial__image-15'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>unite cells that can be united</p>
            <div className='tutorial__image tutorial__image-16'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>filled in cells, that obviously could be filled in</p>
            <div className='tutorial__image tutorial__image-17'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>one more time: find cells that obviously could be filled in</p>
            <div className='tutorial__image tutorial__image-18'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>and do it again, until you finally get the result!</p>
            <div className='tutorial__image tutorial__image-19'></div>
            
            <p className='tutorial__text text text_size-xl text_view-primary text_lineHeight-s'>and now you’re ready to solve your first pickle. i recommend you start with one of theese</p>
            <ul className='tutorial__pickles'>
                {pickle.length > 0 &&
                    pickle.map(item => {
                        return <PicklePreview pickle={item} key={item['id']} />
                    })
                }
            </ul>
        </Layout>
    )
}

export default Tutorial