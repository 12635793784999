import './PicklePreview.css'

const PicklePreview = ({pickle, isLiked, solved, admin}) => {
    return (
        <li className='pickleList__item' style={{ "--pickle-columns": pickle['solution'][0].length, '--pickle-rows': pickle['solution'].length }}>
            <article className={`picklePreview ${admin && pickle['published'] ? ' picklePreview-published': ''}${admin && !pickle['published'] ? ' picklePreview-draft': ''}`}>
                <h2 className="picklePreview__name text text_size-l text_view-primary text_lineHeight-xs text_weight-bold">
                    { solved && pickle['name'] }
                    { !solved && '#' + pickle['id'] }
                </h2>
                { pickle['color'] && (
                    <div className="picklePreview__view theme-light" style={{ "--cell-color-1": pickle['colors'][0],
                                                                "--cell-color-2": pickle['colors'][1],
                                                                "--cell-color-3": pickle['colors'][2],
                                                                "--cell-color-4": pickle['colors'][3],
                                                                "--cell-color-5": pickle['colors'][4],
                                                                "--cell-color-6": pickle['colors'][5],
                                                                "--cell-color-7": pickle['colors'][6],
                                                                "--cell-color-8": pickle['colors'][7],
                                                                "--cell-color-9": pickle['colors'][8] }}>
                        {
                            solved && pickle['solution'].map((row, x) => (
                                <>
                                    {
                                        row.map((cell, y) => (
                                            <div className={`picklePreview__cell`} color={cell} key={y}></div>
                                        ))
                                    }
                                </>
                            ))
                        }
                    </div>
                ) }
                { !pickle['color'] && (
                    <div className="picklePreview__view">
                        {
                            solved && pickle['solution'].map((row, x) => (
                                <>
                                    {
                                        row.map((cell, y) => (
                                            <div className={`picklePreview__cell`} color={cell} key={y}></div>
                                        ))
                                    }
                                </>
                            ))
                        }
                    </div>
                )}
                <div className="picklePreview__footer">
                    {/* <User
                        size="s"
                        showName={true}
                        userID={author['id']}
                        userName={author['name']}
                        userPhoto={author['photo']} /> */}
                    {isLiked ? (
                        <div className="picklePreview__likes text text_size-m text_view-like text_lineHeight-xs">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.31451 13.477L7.31184 13.4754L7.30746 13.4728L7.29363 13.4644L7.24643 13.4355C7.20656 13.4109 7.15016 13.3757 7.07944 13.3306C6.9381 13.2404 6.73891 13.1103 6.49954 12.9455C6.02262 12.6172 5.37712 12.1451 4.70852 11.5722C4.04369 11.0025 3.33059 10.3116 2.73589 9.5421C2.14953 8.78342 1.61662 7.8697 1.42048 6.8578C1.19669 5.70322 1.39788 4.55119 2.10341 3.66664C2.81348 2.77641 3.89451 2.33325 5.07181 2.33325C6.28759 2.33325 7.2982 2.9059 7.92516 3.363C7.95041 3.38141 7.97528 3.3998 7.99975 3.41816C8.02423 3.3998 8.0491 3.38141 8.07435 3.363C8.70132 2.9059 9.71192 2.33325 10.9277 2.33325C13.5578 2.33325 15.0751 4.76974 14.5675 6.91156C14.3347 7.89362 13.7955 8.78795 13.208 9.53876C12.6124 10.3 11.9084 10.9876 11.2533 11.5573C10.5949 12.1298 9.96307 12.603 9.49684 12.9327C9.2629 13.0981 9.0686 13.2288 8.93086 13.3194C8.86194 13.3648 8.80703 13.4002 8.76825 13.4249L8.74191 13.4416L8.72238 13.4539L8.70897 13.4623L8.70473 13.465L8.70217 13.4666C8.27802 13.7294 7.74258 13.7335 7.31451 13.477Z" fill="currentColor"/>
                            </svg>
                            { pickle['likes'] }
                        </div> ) : (
                        <div className="picklePreview__likes text text_size-m text_view-primary text_lineHeight-xs">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.31451 13.477L7.99975 12.3333L8.70217 13.4666C8.27802 13.7294 7.74258 13.7335 7.31451 13.477ZM6.99454 4.33822C6.4792 3.98821 5.80593 3.66659 5.07181 3.66659C3.38824 3.66659 2.40006 4.90468 2.72946 6.60409C3.29341 9.51364 7.99975 12.3333 7.99975 12.3333C7.31451 13.477 7.31477 13.4772 7.31451 13.477L7.31184 13.4754L7.30746 13.4728L7.29363 13.4644C7.2822 13.4575 7.26637 13.4478 7.24643 13.4355C7.20656 13.4109 7.15016 13.3757 7.07944 13.3306C6.9381 13.2404 6.73891 13.1103 6.49954 12.9455C6.02262 12.6172 5.37712 12.1451 4.70852 11.5722C4.04369 11.0025 3.33059 10.3116 2.73589 9.5421C2.14953 8.78342 1.61662 7.8697 1.42048 6.8578C1.19669 5.70322 1.39788 4.55119 2.10341 3.66664C2.81348 2.77641 3.89451 2.33325 5.07181 2.33325C6.28759 2.33325 7.2982 2.9059 7.92516 3.363C7.95041 3.38141 7.97528 3.3998 7.99975 3.41816C8.02423 3.3998 8.0491 3.38141 8.07435 3.363C8.70132 2.9059 9.71192 2.33325 10.9277 2.33325C13.5578 2.33325 15.0751 4.76974 14.5675 6.91156C14.3347 7.89362 13.7955 8.78795 13.208 9.53876C12.6124 10.3 11.9084 10.9876 11.2533 11.5573C10.5949 12.1298 9.96307 12.603 9.49684 12.9327C9.2629 13.0981 9.0686 13.2288 8.93086 13.3194C8.86194 13.3648 8.80703 13.4002 8.76825 13.4249C8.75843 13.4311 8.74964 13.4367 8.74191 13.4416C8.73438 13.4464 8.72786 13.4505 8.72238 13.4539L8.70897 13.4623L8.70473 13.465L8.70217 13.4666C8.70192 13.4667 8.70217 13.4666 7.99975 12.3333C7.99975 12.3333 12.5859 9.49079 13.2701 6.60409C13.5994 5.2142 12.6113 3.66659 10.9277 3.66659C10.1936 3.66659 9.52031 3.98821 9.00497 4.33822C8.38986 4.75599 7.99975 5.21421 7.99975 5.21421C7.99975 5.21421 7.60965 4.75599 6.99454 4.33822Z" fill="CurrentColor"/>
                            </svg>
                            { pickle['likes'] }
                        </div> )
                    }
                    
                    { pickle['difficulty'] == 1 ? (<span className='text text_size-m text_view-success'> easy-peasy</span>) : '' }
                    { pickle['difficulty'] == 2 ? (<span className='text text_size-m text_view-success'> easy</span>) : '' }
                    { pickle['difficulty'] == 3 ? (<span className='text text_size-m text_view-warning'> medium</span>) : '' }
                    { pickle['difficulty'] == 4 ? (<span className='text text_size-m text_view-alert'> hard</span>) : '' }
                    { pickle['difficulty'] == 5 ? (<span className='text text_size-m text_view-alert'> really hard</span>) : '' }
                </div>
                <a href={`/${admin ? 'admin' : ''}${pickle['color'] ? 'color' : 'bw'}-pickle?id=${pickle['id']}`} className="picklePreview__link"></a>
            </article>
        </li>
    )
}

export default PicklePreview