import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'

import Layout from '../components/Layout/Layout'
import Button from '../components/Button/Button'
import IconCheck from '../components/Icon/IconCheck'
import IconPublish from '../components/Icon/IconPublish'
import IconUnpublish from '../components/Icon/IconUnpublish'

const AdminBWPickle = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id')
    
    const [isPublished, setIsPublished] = useState(null)
    const [pickle, setPickle] = useState(null)
    const [matrix, setMatrix] = useState(null)
    const [difficultyInput, setDifficultyInput] = useState('')
    const [nameInput, setNameInput] = useState('')
    
    let cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')
    let selectedCells = null
    
    const handleUpdate = async ({solution, published, difficulty, name}) => {
        let query = {id: pickle['id']}
        if(solution) query.solution = solution
        if(published == true || published == false) query.published = published
        if(difficulty) query.difficulty = difficulty
        if(name) query.name = name
        
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/update-pickle`, {
                params: query
            })
            if(response.data) {
                console.log('updated')
                return true
            }
            else return false
        } catch (error) {
            console.log(error)
        }
    }

    function showCursorNumber(e, length, height, x, y) {
        let content = ''
        
        if(selectedCells != null && selectedCells['array'] && selectedCells['type'] == 'pickleMatrix__cell-checked') {
            if(length == 1 && height == 1) content = ''
            else if(length == 1 && selectedCells != null) {
                let help = height
                let isLine = true
                let helpY = selectedCells['array'][0][1] - 1

                while (isLine && helpY >= 0) {
                    if(matrix[helpY][x] == 1) help++
                    else isLine = false
                    helpY--
                }
                
                helpY = selectedCells['array']
                helpY = helpY[helpY.length - 1]
                helpY = helpY[1]
                helpY++
                isLine = true
                while (isLine && helpY < pickle['rows']) {
                    if(matrix[helpY][x] == 1) help++
                    else isLine = false
                    helpY++
                }
                content = help
            }
            else if(height == 1 && selectedCells != null) {
                let help = length
                let isLine = true
                let helpX = selectedCells['array'][0][0] - 1

                while (isLine && helpX >= 0) {
                    if(matrix[y][helpX] == 1) help++
                    else isLine = false
                    helpX--
                }
                
                helpX = selectedCells['array']
                helpX = helpX[helpX.length - 1]
                helpX = helpX[0]
                helpX++
                isLine = true
                while (isLine && helpX < pickle['columns']) {
                    if(matrix[y][helpX] == 1) help++
                    else isLine = false
                    helpX++
                }
                content = help
            } else content = `${length}×${height}`
            
            cursorNumber.setAttribute('number', content)
        }
    }
    function drawLine(x, y) {
        if(selectedCells != null) {
            const startX = selectedCells['firstCell'][0] < x ? selectedCells['firstCell'][0] : x
            const endX = selectedCells['firstCell'][0] > x ? selectedCells['firstCell'][0] : x
            const startY = selectedCells['firstCell'][1] < y ? selectedCells['firstCell'][1] : y
            const endY = selectedCells['firstCell'][1] > y ? selectedCells['firstCell'][1] : y
            
            const length = Math.abs(startX - endX) + 1
            const height = Math.abs(startY - endY) + 1
            if(selectedCells['type'] == 'pickleMatrix__cell-checked') {
                cursorNumber.classList.remove('pickleMatrix__cursorNumber-hidden')
                showCursorNumber(length, height, x, y)
            }
            
            if(!selectedCells['array']) selectedCells['array'] = [...Array()]
            
            if(selectedCells['array'])
                selectedCells['array'].map(cell => {
                    const block = document.querySelector(`.pickleMatrix__puzzle [axisx='${cell[0]}'][axisy='${cell[1]}']`)
                    
                    if(selectedCells['type'] != '') block.classList.remove(selectedCells['type'])
                    if(cell[2] != '')
                        block.classList.add(cell[2])
                })
            selectedCells['array'] = [...Array()]
            for(let iX = startX; iX < endX + 1; iX++) {
                for(let iY = startY; iY < endY + 1; iY++) {
                    const cell = document.querySelector(`.pickleMatrix__puzzle [axisx='${iX}'][axisy='${iY}']`)
                    let previousClassName = ''
                    
                    if(cell.classList.contains('pickleMatrix__cell-skipped'))
                        previousClassName = 'pickleMatrix__cell-skipped'
                    else if(cell.classList.contains('pickleMatrix__cell-checked'))
                        previousClassName = 'pickleMatrix__cell-checked'
                    
                    selectedCells['array'].push([iX, iY, previousClassName])
                    if(previousClassName != '') cell.classList.remove(previousClassName)
                    if(selectedCells['type'] != '') cell.classList.add(selectedCells['type'])
                }
            }
        }
    }
    function undrawLine() {
        if(selectedCells != null) {
            selectedCells['array'].forEach(item => {
                const cell = document.querySelector(`.pickleMatrix__puzzle [axisx='${item[0]}'][axisy='${item[1]}']`)
                cell.classList.remove(selectedCells['type'])
                if(item[2] != '') cell.classList.add(item[2])
            })
        }
        selectedCells = null
    }
    function updateMatrix(obj) {
        let newMatrix = matrix

        if(obj['type'] == 'pickleMatrix__cell-checked') {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = 1
                })
                else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = 1
        } else if(obj['type'] == 'pickleMatrix__cell-skipped') {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = 0
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = 1
        } else if(obj['type'] == '') {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = ''
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = 1
        }
        setMatrix(newMatrix)
    }

    function OnCellMouseEnter(e) {
        const x = parseInt(e.target.getAttribute('axisx'))
        const y = parseInt(e.target.getAttribute('axisy'))
        
        if(selectedCells != null)
            drawLine(x, y)
    }
    function OnCellMouseLeave(e) {
        const x = e.target.getAttribute('axisx')
        const y = e.target.getAttribute('axisy')
        
        if(cursorNumber) {
            cursorNumber.classList.add('pickleMatrix__cursorNumber-hidden')
            cursorNumber.setAttribute('number', '')
        }
    }
    function OnCellMouseDown(e) {
        cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')
        e.preventDefault()

        const x = parseInt(e.target.getAttribute('axisx'))
        const y = parseInt(e.target.getAttribute('axisy'))
        selectedCells = {}
        
        selectedCells['firstCell'] = [x, y]
        if(e.shiftKey || (e.button == 2)) {
            selectedCells['type'] = 'pickleMatrix__cell-skipped'
        } else if(!e.shiftKey && (e.button != 2) && (e.target.classList.contains('pickleMatrix__cell-checked'))) {
            selectedCells['type'] = ''
        } else if(!e.shiftKey && (e.button != 2) && (e.target.classList.contains('pickleMatrix__cell-skipped'))) {
            selectedCells['type'] = ''
        } else {
            selectedCells['type'] = 'pickleMatrix__cell-checked'
        }
        
    }
    function OnCellMouseUp(e) {
        const x = parseInt(e.target.getAttribute('axisx'))
        const y = parseInt(e.target.getAttribute('axisy'))
        
        drawLine(x, y)
        updateMatrix(selectedCells)
        cursorNumber.classList.add('pickleMatrix__cursorNumber-hidden')
        cursorNumber.setAttribute('number', '')

        if(selectedCells != null) selectedCells = null
    }
    function OnPuzzleMouseLeave(e) {
        const x = e.target.getAttribute('axisx')
        const y = e.target.getAttribute('axisy')

        undrawLine()
    }

    function OnUpdateSolution(e) {
        handleUpdate({solution: matrix})
    }
    function OnPublish(e) {
        handleUpdate({published: true})
        setIsPublished(true)
    }
    function OnUnpublish(e) {
        handleUpdate({published: false})
        setIsPublished(false)
    }
    function OnUpdateName(e) {
        setNameInput(e.target.value)
        handleUpdate({name: e.target.value})
    }
    function OnUpdateDifficulty(e) {
        setDifficultyInput(e.target.value)
        handleUpdate({difficulty: e.target.value})
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER}/get-pickle`, {
            params: {id: parseInt(id)}
        }).then((response) => {
            const solution = response.data['solution']
            const columns = solution[0].length
            const rows = solution.length
            
            const data = {
                'id': response.data['id'],
                'name': response.data['name'],
                'likes': response.data['likes'],
                'creation_date': response.data['creation_date'],
                'columns': columns,
                'rows': rows,
                'solution': response.data['solution'],
                'color': response.data['color'],
                'difficulty': response.data['difficulty'],
                'published': response.data['published']
            }
            setPickle(data)
            setMatrix(response.data['solution'])
            setIsPublished(response.data['published'])
            setDifficultyInput(response.data['difficulty'])
            setNameInput(response.data['name'])

            const headerHeight = document.querySelector('.artboard__header').offsetHeight
            const navWidth = document.querySelector('.header').offsetWidth
            cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')
            
            window.addEventListener('mousemove', (e) => {
                const top = e.clientY - headerHeight + 15
                const left = e.clientX - navWidth + 15
                if(e.defaultPrevented) { return }
                
                cursorNumber.setAttribute('style', `transform: translate(${left}px, ${top}px)`)
                e.preventDefault();
            }, true)
        })
    }, [])

    return (
        <>
            {pickle == null && (
                <Layout width={'full'} page={'bw-pickle'}>
                    <div className='loader'></div>
                </Layout>
            )}
                
            {pickle != null && (
                <Layout width={'full'} page={'bw-pickle'} contentClassName={'artboard'}>
                    <div className='artboard__header'>
                        <div className='text text_size-m text_view-primary text_weight-bold'>#{ pickle['id'] }</div>
                        <input type={'text'} className={'input'} defaultValue={pickle['name']} onChange={OnUpdateName} />
                        <input type={'text'} className={'input'} defaultValue={pickle['difficulty']} onChange={OnUpdateDifficulty} />
        
                        <ul className='artboard__help'>
                            <li className='artboard__helpItem'>
                                <div className='artboard__example pickleMatrix__cell pickleMatrix__cell-checked'></div>
                                <span>— click</span>
                            </li>
                            <li className='artboard__helpItem'>
                                <div className='artboard__example pickleMatrix__cell pickleMatrix__cell-skipped'></div>
                                <span>— right click or Shift + click</span>
                            </li>
                        </ul>
        
                        <div className='artboard__btns'>
                            <Button view={'clear'} iconOnly={true} icon={<IconCheck />} onClick={OnUpdateSolution} />
                            { isPublished && <Button view={'clear'} iconOnly={true} icon={<IconPublish />} onClick={OnUnpublish} /> }
                            { !isPublished && <Button view={'clear'} iconOnly={true} icon={<IconUnpublish />} onClick={OnPublish} /> }
                        </div>
                    </div>
                    
                    <div className='artboard__content'>
                        <div className='pickleMatrix__cursorNumber pickleMatrix__cursorNumber-hidden' color='1'></div>
                        <div className='pickleMatrix' style={{ "--puzzle-cells": pickle['columns'], "--puzzle-height": pickle['solution'].length }}>
                            <div className='pickleMatrix__puzzle' onMouseLeave={OnPuzzleMouseLeave}>
                                {
                                    pickle['solution'].map((row, y) => (
                                        <div className="pickleMatrix__row" key={y}>
                                            {
                                                row.map((cell, x) => (
                                                    <div onContextMenu={e => e.preventDefault()} onMouseDown={OnCellMouseDown} onMouseUp={OnCellMouseUp} onMouseEnter={OnCellMouseEnter} onMouseLeave={OnCellMouseLeave} axisx={x} axisy={y} className={`pickleMatrix__cell ${cell == 1 ? 'pickleMatrix__cell-checked' : ''} ${cell == 0 ? 'pickleMatrix__cell-skipped' : ''}`} key={x}></div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </Layout>
            )}
        </>
    )
}

export default AdminBWPickle