import { useState, useEffect } from 'react'
import axios from 'axios'

const Test = () => {
    const [pickles, setPickles] = useState([])
    let user_id = localStorage.getItem('user_id')
    let page = 0

    // const getPickles = async () => {
    //     try {
    //         const response = await axios.put(`${process.env.REACT_APP_SERVER}/add-like`, {
    //             params: {user_id: 'mrpickles', pickle_id: 4}
    //         })
    //         if(response.data) {
    //             console.log(response.data)
    //             return true
    //         } else return false
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    // const getPickles = async () => {
    //     try {
    //         const response = await axios.put(`${process.env.REACT_APP_SERVER}/update-pickle`, {
    //             params: {id: 4, name: 'first', published: true, difficulty: 999}
    //         })
    //         if(response.data) {
    //             console.log(response.data)
    //             return true
    //         } else return false
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    // const getPickles = async () => {
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-solved-pickles`, {
    //             // params: {skip: page, color: false, published: true}
    //             params: {skip: page, user_id: user_id}
    //         })
    //         if(response.data) {
    //             page = page + 10

    //             // setPickles(pickles => [...pickles, ...response.data])
    //             console.log(response.data)
    //             return true
    //         } else return false
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    const getPickles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-pickles`, {
                params: {skip: page, color: true, published: true, user_id: 'b929ae8d-1dae-428c-b5cf-4125db6fad5b'}
            })
            if(response.data) {
                page = page + 10

                // setPickles(pickles => [...pickles, ...response.data])
                console.log(response.data)
                return true
            } else return false
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getPickles()
    }, [])
    
    return (
        <div></div>
    )
}

export default Test