const undrawLine = (selectedCells) => {
    if(selectedCells != null) {
        selectedCells['array'].forEach(item => {
            const cell = document.querySelector(`.pickleMatrix__puzzle [axisx='${item[0]}'][axisy='${item[1]}']`)
            cell.classList.remove(selectedCells['type'])
            if(item[2] != '') cell.classList.add(item[2])
            if(item[3] != 0) cell.setAttribute('color', item[3])
        })
    }
    selectedCells = null
}

export default undrawLine