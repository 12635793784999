import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import axios from 'axios'

import ColorWin from './ColorWin'
import ColorSolved from './ColorSolved'
import ColorMatrix from './ColorMatrix'

import getTopNumbers from '../../utils/getTopNumbers'
import getLeftNumbers from '../../utils/getLeftNumbers'
import getAuthor from '../../utils/getAuthor'
import getNumberOfCheckedCells from '../../utils/getNumberOfCheckedCells'

import Layout from '../../components/Layout/Layout'

const ColorPickle = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get('id')
    let user_id = localStorage.getItem('user_id')
    
    const [isSolved, setIsSolved] = useState(null)
    const [win, setWin] = useState(null)
    
    const [pickle, setPickle] = useState(null)
    const [author, setAuthor] = useState(null)
    const [user, setUser] = useState(null)
    const [matrix, setMatrix] = useState(null)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER}/get-user`, {
            params: {user_id: user_id}
        }).then((response) => {
            if(response.data) setUser(response.data)
            if(response.data['pickles_solved'].includes(id))
                setIsSolved(true)
        })
        axios.get(`${process.env.REACT_APP_SERVER}/get-pickle`, {
            params: {id: parseInt(id)}
        }).then((response) => {
            getAuthor({id: response.data['author_id'], setAuthor: setAuthor})
            const solution = response.data['solution']
            const columns = solution[0].length
            const rows = solution.length
            const matrix = [...Array(rows)].map(x => [...Array(columns)].map(y => ''))
            const topNumbers = getTopNumbers({solution: solution, numberOfColumns: columns, numberOfRows: rows})
            const leftNumbers = getLeftNumbers({solution: solution, numberOfColumns: columns, numberOfRows: rows})
            const numberOfCheckedCells = getNumberOfCheckedCells({solution: solution, numberOfColumns: columns, numberOfRows: rows})

            const data = {
                'id': response.data['id'],
                'name': response.data['name'],
                'likes': response.data['likes'],
                'creation_date': response.data['creation_date'],
                'author': author,
                'columns': columns,
                'rows': rows,
                'top_numbers': topNumbers['markup'],
                'top_numbers_clear': topNumbers['clear'],
                'left_numbers': leftNumbers['markup'],
                'left_numbers_clear': leftNumbers['clear'],
                'solution': response.data['solution'],
                'color': response.data['color'],
                'difficulty': response.data['difficulty'],
                'colors': response.data['colors'],
                'nemberOfCheckedCells': numberOfCheckedCells
            }
            setPickle(data)
            setMatrix(matrix)
        })
    }, [])

    return (
        <>
            {author != null && isSolved == null && win == null && (
                <Layout width={'full'} page={'color-pickle'} contentClassName={'artboard'}>
                    <ColorMatrix pickle={pickle} setWin={setWin} setMatrix={setMatrix} matrix={matrix} id={id} user_id={user_id} />
                </Layout>
            )}
            {author != null && isSolved != null && win == null && (
                <Layout width={'m'} page={'color-pickle'}>
                    <ColorSolved userID={user_id} pickle={pickle} authorName={author['name']} authorPhoto={author['photo']} />
                </Layout>
            )}
            {author == null && win == null && (
                <Layout width={'m'} page={'color-pickle'}>
                    <div className='loader'></div>
                </Layout>
            )}
            {(win != null) &&
                <Layout width={'m'} page={'color-pickle'}>
                    <ColorWin userID={user_id} pickle={pickle} authorName={author['name']} authorPhoto={author['photo']} />
                </Layout>
            }
        </>
    )
}

export default ColorPickle