import { useState, useEffect } from 'react'
import axios from 'axios'

import BWWin from './BWWin'
import BWSolved from './BWSolved'
import BWMatrix from './BWMatrix'

import getAuthor from '../../utils/getAuthor'
import getTopNumbers from '../../utils/getTopNumbers'
import getLeftNumbers from '../../utils/getLeftNumbers'
import getNumberOfCheckedCells from '../../utils/getNumberOfCheckedCells'

import Layout from '../../components/Layout/Layout'

const BWPickle = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id')
    let user_id = localStorage.getItem('user_id')
    
    const [isSolved, setIsSolved] = useState(null)
    const [win, setWin] = useState(null)
    
    const [pickle, setPickle] = useState(null)
    const [author, setAuthor] = useState(null)
    const [user, setUser] = useState(null)
    const [matrix, setMatrix] = useState(null)

    const getUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-user`, {
                params: {user_id: user_id}
            })
            if(response.data) {
                setUser(response.data)
                
                if(response.data['pickles_solved'].includes(id))
                    setIsSolved(true)
            } else return false
        } catch (error) {
            console.log(error)
        }
    }

    const getPickle = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/get-pickle`, {
                params: {id: parseInt(id)}
            })
            if(response.data) {
                getAuthor({id: response.data['author_id'], setAuthor: setAuthor})
                const solution = response.data['solution']
                const columns = solution[0].length
                const rows = solution.length
                const matrix = [...Array(rows)].map(x => [...Array(columns)].map(y => ''))
                const topNumbers = getTopNumbers({solution: solution, numberOfColumns: columns, numberOfRows: rows})
                const leftNumbers = getLeftNumbers({solution: solution, numberOfColumns: columns, numberOfRows: rows})
                const numberOfCheckedCells = getNumberOfCheckedCells({solution: solution, numberOfColumns: columns, numberOfRows: rows})

                const data = {
                    'id': response.data['id'],
                    'name': response.data['name'],
                    'likes': response.data['likes'],
                    'creation_date': response.data['creation_date'],
                    'author': author,
                    'columns': columns,
                    'rows': rows,
                    'top_numbers': topNumbers['markup'],
                    'top_numbers_clear': topNumbers['clear'],
                    'left_numbers': leftNumbers['markup'],
                    'left_numbers_clear': leftNumbers['clear'],
                    'solution': response.data['solution'],
                    'color': response.data['color'],
                    'difficulty': response.data['difficulty'],
                    'nemberOfCheckedCells': numberOfCheckedCells
                }
                setPickle(data)
                setMatrix(matrix)
            } else return false
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUser()
        getPickle()
    }, [])

    return (
        <>
            {author != null && isSolved == null && win == null && (
                <Layout width={'full'} page={'bw-pickle'} contentClassName={'artboard'}>
                    <BWMatrix pickle={pickle} setWin={setWin} setMatrix={setMatrix} matrix={matrix} id={id} user_id={user_id} />
                </Layout>
            )}
            {author != null && isSolved != null && win == null && (
                <Layout width={'m'} page={'bw-pickle'}>
                    <BWSolved userID={user_id} pickleID={pickle['id']} pickleName={pickle['name']} authorID={author['id']} authorName={author['name']} authorPhoto={author['photo']} solution={pickle['solution']} puzzleColumns={pickle['columns']} />
                </Layout>
            )}
            {author == null && win == null && (
                <Layout width={'m'} page={'bw-pickle'}>
                    <div className='loader'></div>
                </Layout>
            )}
            {(win != null) &&
                <Layout width={'m'} page={'bw-pickle'}>
                    <BWWin userID={user_id} pickleID={pickle['id']} pickleName={pickle['name']} authorID={author['id']} authorName={author['name']} authorPhoto={author['photo']} solution={pickle['solution']} puzzleColumns={pickle['columns']} />
                </Layout>
            }
        </>
    )
}

export default BWPickle