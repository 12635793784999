import './MatrixHeader.css'

import Button from '../../components/Button/Button'
import IconStartOver from '../../components/Icon/IconStartOver'
import IconNextStep from '../../components/Icon/IconNextStep'
import IconPrevStep from '../../components/Icon/IconPrevStep'
import IconPlus from '../../components/Icon/IconPlus'
import IconMinus from '../../components/Icon/IconMinus'

const MatrixHeader = ({pickle, color, setColor, zoom, setZoom, setMatrix, setProgress, updateMatrix, historyStep, setHistoryStep, history, setHistory}) => {
    function OnPrevState() {
        if(history.length > 0 && historyStep + 1 < history.length) {
            const thisMatrix = history[historyStep + 1]
            
            setHistoryStep(historyStep + 1)
            setMatrix(JSON.parse(JSON.stringify(thisMatrix)))

            for(let y = 0; y < pickle['rows']; y++) {
                for(let x = 0; x < pickle['columns']; x++) {
                    const cell = document.querySelector(`.pickleMatrix__puzzle .pickleMatrix__cell[axisx='${x}'][axisy='${y}']`)

                    if(thisMatrix[y][x] === 0) {
                        cell.classList.remove('pickleMatrix__cell-checked')
                        cell.classList.add('pickleMatrix__cell-skipped')
                        cell.setAttribute('color', '')
                    } else if(thisMatrix[y][x] === '') {
                        cell.classList.remove('pickleMatrix__cell-checked')
                        cell.classList.remove('pickleMatrix__cell-skipped')
                        cell.setAttribute('color', '')
                    } else if(thisMatrix[y][x] > 0) {
                        cell.classList.add('pickleMatrix__cell-checked')
                        cell.classList.remove('pickleMatrix__cell-skipped')
                        cell.setAttribute('color', thisMatrix[y][x])
                    }
                }
            }
            updateMatrix()
        }
    }

    function OnNextState() {
        if(historyStep > 0) {
            const thisMatrix = history[historyStep - 1]
            
            setHistoryStep(historyStep - 1)
            setMatrix(JSON.parse(JSON.stringify(thisMatrix)))

            for(let y = 0; y < pickle['rows']; y++) {
                for(let x = 0; x < pickle['columns']; x++) {
                    const cell = document.querySelector(`.pickleMatrix__puzzle .pickleMatrix__cell[axisx='${x}'][axisy='${y}']`)

                    if(thisMatrix[y][x] === 0) {
                        cell.classList.remove('pickleMatrix__cell-checked')
                        cell.classList.add('pickleMatrix__cell-skipped')
                        cell.setAttribute('color', '')
                    } else if(thisMatrix[y][x] === '') {
                        cell.classList.remove('pickleMatrix__cell-checked')
                        cell.classList.remove('pickleMatrix__cell-skipped')
                        cell.setAttribute('color', '')
                    } else if(thisMatrix[y][x] > 0) {
                        cell.classList.add('pickleMatrix__cell-checked')
                        cell.classList.remove('pickleMatrix__cell-skipped')
                        cell.setAttribute('color', thisMatrix[y][x])
                    }
                }
            }
            updateMatrix()
        }
    }

    function OnPaletteClick(e) {
        const className = 'artboard__example-checked'
        const previous = document.querySelector(`.${className}`)
        
        if(previous) previous.classList.remove(className)
        e.target.classList.add(className)
        setColor(e.target.getAttribute('color'))
    }

    function OnReset(e) {
        const newMatrix = [...Array(pickle['solution'].length)].map(x => [...Array(pickle['solution'][0].length)].map(y => ''))
        const checkedNumbers = document.querySelectorAll('.pickleMatrix .pickleMatrix__number-checked')
        const allCells = document.querySelectorAll('.pickleMatrix__puzzle .pickleMatrix__cell')
        setMatrix(newMatrix)

        checkedNumbers.forEach(item => item.classList.remove('pickleMatrix__number-checked'))
        allCells.forEach(item => item.setAttribute('color', ''))

        setHistory([[...Array(pickle['rows'])].map(x => [...Array(pickle['columns'])].map(y => ''))])
        setHistoryStep(0)
        setProgress(0)
    }

    function OnZoomIn() {
        if(zoom < 40) setZoom(zoom + 4)
    }
    
    function OnZoomOut() {
        if(zoom > 12) setZoom(zoom - 4)
    }

    return (
        <div className='artboard__header'>
            <div className='text text_size-m text_view-primary text_weight-bold'>#{ pickle['id'] }</div>
            { pickle['difficulty'] == 1 ? (<div className='text text_size-m text_view-success'>easy-peasy</div>) : '' }
            { pickle['difficulty'] == 2 ? (<div className='text text_size-m text_view-success'>easy</div>) : '' }
            { pickle['difficulty'] == 3 ? (<div className='text text_size-m text_view-warning'>medium</div>) : '' }
            { pickle['difficulty'] == 4 ? (<div className='text text_size-m text_view-alert'>hard</div>) : '' }
            { pickle['difficulty'] == 5 ? (<div className='text text_size-m text_view-alert'>really hard</div>) : '' }

            <ul className='artboard__help'>
                <li className='artboard__helpItem'>
                    { pickle['colors'] && pickle['colors'].map((item, i) => {
                        return <div onClick={OnPaletteClick} className={`artboard__example artboard__example-palette ${color == (i + 1) ? 'artboard__example-checked' : ''} pickleMatrix__cell pickleMatrix__cell-checked`} color={i+1} key={i}></div>
                      }) }
                    { !pickle['colors'] && <div className='artboard__example pickleMatrix__cell pickleMatrix__cell-checked'></div> }
                    <span>— click</span>
                </li>
                <li className='artboard__helpItem'>
                    <div className='artboard__example pickleMatrix__cell pickleMatrix__cell-skipped'></div>
                    <span>— right click or Shift + click</span>
                </li>
            </ul>

            <div className='artboard__btns'>
                <div className='artboard__scale'>
                    <Button view={'clear'} icon={<IconMinus />} iconOnly={true} onClick={OnZoomOut} />
                    <div className='text text_size-m text_view-primary artboard__scaleText'>{zoom * 5}%</div>
                    <Button view={'clear'} icon={<IconPlus />} iconOnly={true} onClick={OnZoomIn} />
                </div>
                <Button view={'clear'} iconOnly={true} icon={<IconPrevStep />} onClick={OnPrevState} disabled={history.length < 2 || history.length == historyStep+1} />
                <Button view={'clear'} iconOnly={true} icon={<IconNextStep />} onClick={OnNextState} disabled={historyStep == 0} />
                <Button view={'clear'} icon={<IconStartOver />} iconOnly={true} onClick={OnReset} disabled={history.length < 2} />
            </div>
        </div>
    )
}

export default MatrixHeader