const IconColorAdmin = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2H20C21.1046 2 22 2.89543 22 4V12H12V2Z" fill="var(--color-bg-brand)"/>
            <path d="M6.14407 10.1201C5.62407 10.1201 5.15207 9.98812 4.72807 9.72412C4.31207 9.46012 3.98007 9.10412 3.73207 8.65612C3.49207 8.20012 3.37207 7.69212 3.37207 7.13212C3.37207 6.56412 3.49207 6.05612 3.73207 5.60812C3.98007 5.15212 4.31207 4.79212 4.72807 4.52812C5.15207 4.25612 5.62407 4.12012 6.14407 4.12012C6.58407 4.12012 6.97207 4.21612 7.30807 4.40812C7.65207 4.59212 7.92407 4.84812 8.12407 5.17612C8.32407 5.50412 8.42407 5.87612 8.42407 6.29212V7.94812C8.42407 8.36412 8.32407 8.73612 8.12407 9.06412C7.93207 9.39212 7.66407 9.65212 7.32007 9.84412C6.97607 10.0281 6.58407 10.1201 6.14407 10.1201ZM6.36007 8.87212C6.84807 8.87212 7.24007 8.70812 7.53607 8.38012C7.84007 8.05212 7.99207 7.63212 7.99207 7.12012C7.99207 6.77612 7.92407 6.47212 7.78807 6.20812C7.65207 5.94412 7.46007 5.74012 7.21207 5.59612C6.97207 5.44412 6.68807 5.36812 6.36007 5.36812C6.04007 5.36812 5.75607 5.44412 5.50807 5.59612C5.26807 5.74012 5.07607 5.94412 4.93207 6.20812C4.79607 6.47212 4.72807 6.77612 4.72807 7.12012C4.72807 7.46412 4.79607 7.76812 4.93207 8.03212C5.07607 8.29612 5.26807 8.50412 5.50807 8.65612C5.75607 8.80012 6.04007 8.87212 6.36007 8.87212ZM7.90807 10.0001V8.45212L8.13607 7.04812L7.90807 5.65612V4.24012H9.22807V10.0001H7.90807Z" fill="currentColor"/>
            <path d="M2 12H12V22H4C2.89543 22 2 21.1046 2 20V12Z" fill="currentColor"/>
        </svg>
    )
}

export default IconColorAdmin