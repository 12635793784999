import axios from 'axios'
import { useState, useEffect } from 'react'

import './pickleMatrix.css'

import MatrixHeader from '../../components/MatrixHeader/MatrixHeader'
import Matrix from '../../components/Matrix/Matrix'

import getNumberOfCheckedCells from '../../utils/getNumberOfCheckedCells'

const BWMatrix = ({pickle, setWin, setMatrix, matrix, id, user_id}) => {
    let cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')
    
    const [color, setColor] = useState(1)
    const [history, setHistory] = useState([[...Array(pickle['rows'])].map(x => [...Array(pickle['columns'])].map(y => ''))])
    const [historyStep, setHistoryStep] = useState(0)
    const [progress, setProgress] = useState(0)
    const [zoom, setZoom] = useState(20)

    function updateMatrix(obj) {
        let newMatrix = matrix
        
        if(obj && !obj['isEmpty'] && !obj['isSkipped']) {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = parseInt(obj['color'])
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = parseInt(obj['color'])
        } else if(obj && obj['isSkipped']) {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = 0
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = 0
        } else if(obj && obj['isEmpty']) {
            if(obj['array'])
                obj['array'].forEach(coords => {
                    const x = coords[0]
                    const y = coords[1]
                    newMatrix[y][x] = ''
                })
            else newMatrix[obj['firstCell'][0]][obj['firstCell'][1]] = ''
        }
        
        let checkedNumbers = getNumberOfCheckedCells({solution: newMatrix, numberOfColumns: pickle['columns'], numberOfRows: pickle['rows']})
        let progressPercent = Math.floor(checkedNumbers/(pickle['nemberOfCheckedCells'] / 100))
        if(checkedNumbers == pickle['nemberOfCheckedCells']) progressPercent = 100
        
        setMatrix(newMatrix)
        setProgress(progressPercent)
    }

    function userWin() {
        setWin(true)
        axios.put(`${process.env.REACT_APP_SERVER}/add-win`, {
            params: {pickle_id: id, user_id: user_id, difficulty: pickle['difficulty'], isColor: true}
        })
    }

    useEffect(() => {
        const headerHeight = document.querySelector('.artboard__header').offsetHeight
        const navWidth = document.querySelector('.header').offsetWidth
        cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')

        window.addEventListener('mousemove', (e) => {
            const top = e.clientY - headerHeight + 15
            const left = e.clientX - navWidth + 15
            if(e.defaultPrevented) { return }
            
            cursorNumber.setAttribute('style', `transform: translate(${left}px, ${top}px)`)
            e.preventDefault();
        }, true)
    }, [])

    return (
        <main className="artboard">
            <MatrixHeader pickle={pickle} color={color} setColor={setColor} zoom={zoom} setZoom={setZoom} setMatrix={setMatrix} setProgress={setProgress} updateMatrix={updateMatrix} historyStep={historyStep} setHistoryStep={setHistoryStep} history={history} setHistory={setHistory} />
            <Matrix pickle={pickle} zoom={zoom} color={color} matrix={matrix} setMatrix={setMatrix} progress={progress} setProgress={setProgress} history={history} updateMatrix={updateMatrix} setHistory={setHistory} setHistoryStep={setHistoryStep} historyStep={historyStep} userWin={userWin} setColor={setColor} isColor={false} />
        </main>
    )
}

export default BWMatrix