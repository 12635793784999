import { useState, useEffect } from 'react'

import './Matrix.css'
import './MatrixHovers.css'

import drawLine from '../../utils/drawLine'

const Matrix = ({isColor, pickle, zoom, color, matrix, setMatrix, progress, setProgress, history, setHistory, setHistoryStep, historyStep, userWin, setColor, updateMatrix}) => {
    let cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')
    let selectedCells = null
    let colorProperties = {}

    function addToHistory() {
        const newMatrix = JSON.parse(JSON.stringify(matrix))
        let newHistory = history
        
        if(historyStep == 0) {
            newHistory.unshift(newMatrix)
        } else {
            let newHistory = history
            newHistory.splice(0, historyStep)
            newHistory.unshift(newMatrix)
            setHistoryStep(0)
        }
        
        if(newHistory.length > 10)
            newHistory.pop()
        
        setHistory(newHistory)
    }

    function checkMatrix() {
        if(selectedCells && !selectedCells['isEmpty'] && selectedCells['array']) {
            let xcords = []
            let ycords = []
            selectedCells['array'].forEach(item => {
                if(!xcords.includes(item[0])) xcords.push(item[0])
                if(!ycords.includes(item[1])) ycords.push(item[1])
            })

            // vertical lines
            xcords.forEach(x => {
                let topNumbers = [...Array()]
                let matrixNumbers = [...Array()]
                let help = 0

                pickle['top_numbers_clear'][x].map(item => topNumbers.push([item[0], item[1]]))
                
                for(let i = 0; i < pickle['rows']; i++) {
                    if(matrix[i][x] != 0) {
                        if(help != 0 && matrix[i][x] == matrix[i-1][x]) {
                            help++
                        } else if(help != 0 && matrix[i][x] != matrix[i-1][x]) {
                            matrixNumbers.push([help, matrix[i-1][x]])
                            help = 1
                        } else {
                            help++
                        }
                    } else if(help != 0) {
                        matrixNumbers.push([help, matrix[i-1][x]])
                        help = 0
                    } else help = 0
                }
                if(help != 0) matrixNumbers.push([help, matrix[pickle['rows'] - 1][x]])

                if(JSON.stringify(matrixNumbers) == JSON.stringify(topNumbers)) {
                    const col = document.querySelectorAll(`.pickleMatrix__puzzle .pickleMatrix__cell[axisx='${x}'][color='']`)
                    const numberCol = document.querySelectorAll(`.pickleMatrix__topNumbers .pickleMatrix__cell[axisx='${x}']`)
                    
                    col.forEach(cell => { 
                        cell.setAttribute('color', 0)
                        matrix[cell.getAttribute('axisy')][cell.getAttribute('axisx')] = 0
                    })
                    numberCol.forEach(cell => { 
                        cell.classList.add('pickleMatrix__number-checked')
                    })
                } else {
                    const numberCol = document.querySelectorAll(`.pickleMatrix__topNumbers .pickleMatrix__cell[axisx='${x}']`)
                    let y = 0

                    numberCol.forEach(cell => { 
                        cell.classList.remove('pickleMatrix__number-checked')
                    })

                    for(let i = 0; i < topNumbers.length; i++) {
                        let isSmallGap = true
                        let numberOfChecked = 0
                        let firstCellToSkip = y
                        let gap
                        
                        while (y < pickle['rows'] && isSmallGap) {
                            gap = 0
                            while(y < pickle['rows'] && matrix[y][x] === 0) y++
                            while(y < pickle['rows'] && matrix[y][x] === '') {
                                y++
                                gap++
                            }
                            if(gap < topNumbers[i][0] && (matrix[y][x] == 0 || matrix[y][x] == '')) isSmallGap = true
                            else isSmallGap = false
                        }
                        let lastCellToSkip = y - 1
                        
                        while(y < pickle['rows'] && matrix[y][x] > 0 && matrix[y][x] == topNumbers[i][1]) {
                            y++
                            numberOfChecked++
                        }
                        
                        if(topNumbers[i][0] == numberOfChecked && topNumbers[i][0] > gap) {
                            const cell = document.querySelector(`.pickleMatrix__puzzle .pickleMatrix__cell[axisx='${x}'][axisy='${y}']`)
                            const cellNumber = document.querySelector(`.pickleMatrix__topNumbers .pickleMatrix__cell[axisx='${pickle['top_numbers_clear'][x][i][2]}'][axisy='${pickle['top_numbers_clear'][x][i][3]}']`)

                            cellNumber.classList.add('pickleMatrix__number-checked')
                            for(let helpY = firstCellToSkip; helpY <= lastCellToSkip; helpY++) {
                                const cell = document.querySelector(`.pickleMatrix__puzzle .pickleMatrix__cell[axisx='${x}'][axisy='${helpY}']`)
                                cell.setAttribute('color', 0)
                                matrix[helpY][x] = 0
                            }
                            if(cell && topNumbers[i+1] && topNumbers[i][1] == topNumbers[i+1][1] && cell.getAttribute('color') == '') {
                                cell.setAttribute('color', 0)
                                matrix[y][x] = 0
                            }
                        } else i = topNumbers.length
                    }

                    y = pickle['rows'] - 1

                    for(let i = (topNumbers.length - 1); i >= 0; i--) {
                        let isSmallGap = true
                        let numberOfChecked = 0
                        let firstCellToSkip = y
                        let gap
                        
                        while (y > 0 && isSmallGap) {
                            gap = 0
                            while(y > 0 && matrix[y][x] === 0) y--
                            while(y > 0 && matrix[y][x] === '') {
                                y--
                                gap++
                            }
                            if(gap < topNumbers[i][0] && (matrix[y][x] == 0 || matrix[y][x] == '')) isSmallGap = true
                            else isSmallGap = false
                        }
                        let lastCellToSkip = y + 1
                        
                        while(y > 0 && matrix[y][x] > 0 && matrix[y][x] == topNumbers[i][1]) {
                            y--
                            numberOfChecked++
                        }
                        
                        if(topNumbers[i][0] == numberOfChecked && topNumbers[i][0] > gap) {
                            const cell = document.querySelector(`.pickleMatrix__puzzle .pickleMatrix__cell[axisx='${x}'][axisy='${y}']`)
                            const cellNumber = document.querySelector(`.pickleMatrix__topNumbers .pickleMatrix__cell[axisx='${pickle['top_numbers_clear'][x][i][2]}'][axisy='${pickle['top_numbers_clear'][x][i][3]}']`)

                            cellNumber.classList.add('pickleMatrix__number-checked')
                            for(let helpY = lastCellToSkip; helpY <= firstCellToSkip; helpY++) {
                                const cell = document.querySelector(`.pickleMatrix__puzzle .pickleMatrix__cell[axisx='${x}'][axisy='${helpY}']`)
                                cell.setAttribute('color', 0)
                                matrix[helpY][x] = 0
                            }
                            if(cell && topNumbers[i-1] && topNumbers[i][1] == topNumbers[i-1][1] && cell.getAttribute('color') == '') {
                                cell.setAttribute('color', 0)
                                matrix[y][x] = 0
                            }
                        } else i = -1
                    }
                }
            })

            // horisontal lines
            ycords.forEach(y => {
                let leftNumbers = [...Array()]
                let matrixNumbers = [...Array()]
                let help = 0

                pickle['left_numbers_clear'][y].map(item => leftNumbers.push([item[0], item[1]]))
                
                for(let i = 0; i < pickle['columns']; i++) {
                    if(matrix[y][i] != 0) {
                        if(help != 0 && matrix[y][i] == matrix[y][i-1]) {
                            help++
                        } else if(help != 0 && matrix[y][i] != matrix[y][i-1]) {
                            matrixNumbers.push([help, matrix[y][i-1]])
                            help = 1
                        } else {
                            help++
                        }
                    } else if(help != 0) {
                        matrixNumbers.push([help, matrix[y][i-1]])
                        help = 0
                    } else help = 0
                }
                if(help != 0) matrixNumbers.push([help, matrix[y][pickle['columns'] - 1]])

                if(JSON.stringify(matrixNumbers) == JSON.stringify(leftNumbers)) {
                    const col = document.querySelectorAll(`.pickleMatrix__puzzle .pickleMatrix__cell[axisy='${y}'][color='']`)
                    const numberCol = document.querySelectorAll(`.pickleMatrix__leftNumbers .pickleMatrix__cell[axisy='${y}']`)

                    col.forEach(cell => { 
                        cell.setAttribute('color', 0)
                        matrix[cell.getAttribute('axisy')][cell.getAttribute('axisx')] = 0
                    })
                    numberCol.forEach(cell => { 
                        cell.classList.add('pickleMatrix__number-checked')
                    })
                } else {
                    const numberCol = document.querySelectorAll(`.pickleMatrix__leftNumbers .pickleMatrix__cell[axisy='${y}']`)
                    let x = 0

                    numberCol.forEach(cell => { 
                        cell.classList.remove('pickleMatrix__number-checked')
                    })

                    for(let i = 0; i < leftNumbers.length; i++) {
                        let isSmallGap = true
                        let numberOfChecked = 0
                        let firstCellToSkip = x
                        let gap
                        
                        while (x < pickle['columns'] && isSmallGap) {
                            gap = 0
                            while(x < pickle['columns'] && matrix[y][x] === 0) x++
                            while(x < pickle['columns'] && matrix[y][x] === '') {
                                x++
                                gap++
                            }
                            if(gap < leftNumbers[i][0] && (matrix[y][x] == 0 || matrix[y][x] == '')) isSmallGap = true
                            else isSmallGap = false
                        }
                        let lastCellToSkip = x - 1
                        
                        while(x < pickle['columns'] && matrix[y][x] > 0 && matrix[y][x] == leftNumbers[i][1]) {
                            x++
                            numberOfChecked++
                        }
                        
                        if(leftNumbers[i][0] == numberOfChecked && leftNumbers[i][0] > gap) {
                            const cell = document.querySelector(`.pickleMatrix__puzzle .pickleMatrix__cell[axisx='${x}'][axisy='${y}']`)
                            const cellNumber = document.querySelector(`.pickleMatrix__leftNumbers .pickleMatrix__cell[axisx='${pickle['left_numbers_clear'][y][i][2]}'][axisy='${pickle['left_numbers_clear'][y][i][3]}']`)

                            cellNumber.classList.add('pickleMatrix__number-checked')
                            for(let helpX = firstCellToSkip; helpX <= lastCellToSkip; helpX++) {
                                const cell = document.querySelector(`.pickleMatrix__puzzle .pickleMatrix__cell[axisy='${y}'][axisx='${helpX}']`)
                                cell.setAttribute('color', 0)
                                matrix[y][helpX] = 0
                            }
                            if(cell && leftNumbers[i+1] && leftNumbers[i][1] == leftNumbers[i+1][1] && cell.getAttribute('color') == '') {
                                cell.setAttribute('color', 0)
                                matrix[y][x] = 0
                            }
                        } else i = leftNumbers.length
                    }

                    x = pickle['columns'] - 1

                    for(let i = (leftNumbers.length - 1); i >= 0; i--) {
                        let isSmallGap = true
                        let numberOfChecked = 0
                        let firstCellToSkip = x
                        let gap
                        
                        while (x > 0 && isSmallGap) {
                            gap = 0
                            while(x > 0 && matrix[y][x] === 0) x--
                            while(x > 0 && matrix[y][x] === '') {
                                x--
                                gap++
                            }
                            if(gap < leftNumbers[i][0] && (matrix[y][x] == 0 || matrix[y][x] == '')) isSmallGap = true
                            else isSmallGap = false
                        }
                        let lastCellToSkip = x + 1
                        
                        while(x > 0 && matrix[y][x] > 0 && matrix[y][x] == leftNumbers[i][1]) {
                            x--
                            numberOfChecked++
                        }
                        
                        if(leftNumbers[i][0] == numberOfChecked && leftNumbers[i][0] > gap) {
                            const cell = document.querySelector(`.pickleMatrix__puzzle .pickleMatrix__cell[axisx='${x}'][axisy='${y}']`)
                            const cellNumber = document.querySelector(`.pickleMatrix__leftNumbers .pickleMatrix__cell[axisx='${pickle['left_numbers_clear'][y][i][2]}'][axisy='${pickle['left_numbers_clear'][y][i][3]}']`)

                            cellNumber.classList.add('pickleMatrix__number-checked')
                            for(let helpX = lastCellToSkip; helpX <= firstCellToSkip; helpX++) {
                                const cell = document.querySelector(`.pickleMatrix__puzzle .pickleMatrix__cell[axisy='${y}'][axisx='${helpX}']`)
                                cell.setAttribute('color', 0)
                                matrix[y][helpX] = 0
                            }
                            if(cell && leftNumbers[i-1] && leftNumbers[i][1] == leftNumbers[i-1][1] && cell.getAttribute('color') == '') {
                                cell.setAttribute('color', 0)
                                matrix[y][x] = 0
                            }
                        } else i = -1
                    }
                }
            })
        }
    }

    function OnCellMouseEnter(e) {
        const x = parseInt(e.target.getAttribute('axisx'))
        const y = parseInt(e.target.getAttribute('axisy'))
        
        if(selectedCells != null) drawLine(x, y, selectedCells, matrix, pickle)
    }

    function OnCellMouseDown(e) {
        e.preventDefault()

        const x = parseInt(e.target.getAttribute('axisx'))
        const y = parseInt(e.target.getAttribute('axisy'))
        
        selectedCells = {}
        selectedCells['firstCell'] = [x, y]

        if(!e.shiftKey && (e.button != 2) && parseInt(e.target.getAttribute('color')) === 0) {
            selectedCells['isEmpty'] = true
            selectedCells['isSkipped'] = false
            selectedCells['color'] = ''
        } else if(!e.shiftKey && (e.button != 2) && e.target.getAttribute('color') > 0) {
            selectedCells['isEmpty'] = true
            selectedCells['isSkipped'] = false
            selectedCells['color'] = ''
        } else if(!e.shiftKey && (e.button != 2)) {
            selectedCells['isEmpty'] = false
            selectedCells['isSkipped'] = false
            selectedCells['color'] = color
        } else if(e.shiftKey || (e.button == 2)) {
            selectedCells['isEmpty'] = false
            selectedCells['isSkipped'] = true
            selectedCells['color'] = 0
        } else {
            selectedCells['isEmpty'] = false
            selectedCells['isSkipped'] = false
            selectedCells['color'] = color
        }
    }

    function OnCellMouseUp(e) {
        const x = parseInt(e.target.getAttribute('axisx'))
        const y = parseInt(e.target.getAttribute('axisy'))
        
        drawLine(x, y, selectedCells, matrix, pickle)
        updateMatrix(selectedCells)
        checkMatrix()
        cursorNumber.setAttribute('number', '')
        
        if(selectedCells != null) {
            let newMatrix = matrix

            addToHistory()
            newMatrix.map((row, y) => {
                row.map((cell, x) => {
                    if(cell == '') cell = 0
                })
            })
            
            if(JSON.stringify(newMatrix) == JSON.stringify(pickle['solution'])) {
                userWin()
            }
            selectedCells = null
        }
    }

    function OnPuzzleMouseLeave(e) {
        const x = parseInt(e.target.getAttribute('axisx'))
        const y = parseInt(e.target.getAttribute('axisy'))
        
        drawLine(x, y, selectedCells, matrix, pickle)
        updateMatrix(selectedCells)
        checkMatrix()
        cursorNumber.setAttribute('number', '')
    }

    function OnNumberClick(e) {
        const cellColor = e.target.getAttribute('color')
        if(cellColor) setColor(cellColor)
    }

    useEffect(() => {
        const headerHeight = document.querySelector('.artboard__header').offsetHeight
        const navWidth = document.querySelector('.header').offsetWidth
        cursorNumber = document.querySelector('.pickleMatrix__cursorNumber')

        window.addEventListener('mousemove', (e) => {
            const top = e.clientY - headerHeight + 15
            const left = e.clientX - navWidth + 15
            if(e.defaultPrevented) { return }
            
            cursorNumber.setAttribute('style', `transform: translate(${left}px, ${top}px)`)
            e.preventDefault();
        }, true)
    }, [])

    return (
        <div className='artboard__content' style={{ "--cell-size": zoom + 'px', "--cell-font-size": 16*zoom*4/100 + 'px' }}>
            <div className='pickleMatrix__cursorNumber' color={color}></div>
            <div className={`pickleMatrix ${isColor ? 'pickleMatrix-color' : ''}`} style={{ "--puzzle-cells": pickle['columns'], 
                                                    "--left-cells": pickle['left_numbers'][0].length,
                                                    "--puzzle-top-height": pickle['top_numbers'].length,
                                                    "--puzzle-height": matrix.length, colorProperties }}>
                <div className='pickleMatrix__preview'>
                    { progress < 100 && <div className='pickleMatrix__progress' style={{ '--progress': progress+'%' }} number={progress+'%'}></div> }
                    { progress == 100 && <div className='pickleMatrix__progress' style={{ '--progress': progress+'%' }} number={'check again'}></div> }
                </div>
                <div className='pickleMatrix__topNumbers'>
                    {
                        pickle['top_numbers'].map((row, y) => (
                            <div className='pickleMatrix__row' key={y}>
                                {
                                    row.map((cell, x) => (
                                        <div onClick={OnNumberClick} axisx={x} axisy={y} color={cell[1]} className={`pickleMatrix__cell pickleMatrix__number`} number={cell[0] != '' ? cell[0] : ''} key={x}></div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
                <div className='pickleMatrix__topNoNumbers'></div>
                
                <div className='pickleMatrix__leftNumbers'>
                    {
                        pickle['left_numbers'].map((row, y) => (
                            <div className='pickleMatrix__row' key={y}>
                                {
                                    row.map((cell, x) => (
                                        <div onClick={OnNumberClick} axisx={x} axisy={y} color={cell[1]} className={`pickleMatrix__cell pickleMatrix__number`} number={cell[0] != '' ? cell[0] : ''} key={x}></div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
                <div className={`pickleMatrix__puzzle ${isColor && 'theme-light'}`} onMouseLeave={OnPuzzleMouseLeave}>
                    {
                        matrix.map((row, y) => (
                            <div className="pickleMatrix__row" key={y}>
                                {
                                    row.map((cell, x) => (
                                        <div onContextMenu={e => e.preventDefault()} onMouseDown={OnCellMouseDown} onMouseUp={OnCellMouseUp} onMouseEnter={OnCellMouseEnter} axisx={x} axisy={y} color={''} className="pickleMatrix__cell" key={x}></div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
                <div className='pickleMatrix__rightNumbers'>
                    {
                        matrix.map((cell, y) => (
                            (<div className='pickleMatrix__cell' axisy={y} key={y}>{y+1}</div>)
                        ))
                    }
                </div>
                
                <div className='pickleMatrix__bottomNoNumbers'></div>
                <div className='pickleMatrix__bottomNumbers'>
                    {
                        matrix[0].map((cell, x) => (
                            (<div className="pickleMatrix__cell" axisx={x} key={x}>{x+1}</div>)
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Matrix